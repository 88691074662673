import axios from 'axios'
import Swal from 'sweetalert2'
import jwtAuthService from "./jwtAuthService";
import { API_BASE_URL } from "./ApiConstant";
import { setError } from '../redux/actions/errorActions';
import history from "../helpers/history.js";

let reduxStore

export const injectStore = _store => {
    reduxStore = _store
}

const axiosInstance = axios.create({
    baseURL: API_BASE_URL
});

axiosInstance.interceptors.response.use(    
    response => { return response },
    error => {
    if (401 === error.response.status) {
        return Swal.fire({
                title: "Oturum Süresi Doldu...",
                text: "Yeniden giriş yapmanız gerekmektedir",
                timer: 2000,
                focusCancel: false,
                timerProgressBar: true,
                willClose: () => {
                    jwtAuthService.logout();
                }
        });
    }
    else if (402 === error.response.status) {
        return Swal.fire({
                title: "Lisans Süresi Bitti",
                text: "Ödeme sayfasına yönlendiriliyorsunuz..",
                timer: 3000,
                focusCancel: false,
                timerProgressBar: true,
                willClose: () => {
                    history.push({ pathname: "/payment" });
                    //jwtAuthService.logout();
                }
        });
    } else if (500 === error.response.status) {
        if(error?.response?.data?.message){
            return Swal.fire({
                title: "Sunucu Hatası...",
                text: error.response.data.message,
                timer: 3000,
                focusCancel: false,
                timerProgressBar: true,
                willClose: () => {
                    return Promise.reject(error);
                }
        });
        }
        else{
            return Swal.fire({
                title: "Sunucu Hatası...",
                text: "Bağlantı sağlanamadı.Lütfen tekrar deneyiniz.",
                timer: 3000,
                focusCancel: false,
                timerProgressBar: true,
                willClose: () => {
                    return Promise.reject(error);
                }
        });
        }

    }
    
    return Promise.reject(error);
    
    }
);


export default {
    
    axiosInstance : axiosInstance,


    getQueryData: async ({url,params,method='POST'}) => {
        return await axiosInstance({
            'method'        : method,
            'url'           : url,
            'params'        : params,
        }).then((response)=>{
            return response.data;
        }).catch(function(error) {
            let errorText = "";
            if (!error.response) {
                errorText =  "Lütfen internet bağlantınızı kontrol ediniz.";
            }
            else{
                errorText = error?.response?.statusText
            }

            Swal.fire({
                title: "Hata Oluştu",
                text: errorText,
                focusCancel: false,
                timerProgressBar: true
            });
        });
    },

    getData: async (url,data) => {
        let result;

        await axiosInstance({
            'method':'GET',
            'url':url,
            'params': data,
        }).then((response)=>{
            result = response;
        }).catch((error)=>{
            setError(error)
        })

        return result;
    },

    postData: async (url,data) => {
        let result;

        await axiosInstance.post(url,data).then((response)=>{
            result = response;
        }).catch((error)=>{
            setError(error)
        })

        return result;
    },

}

