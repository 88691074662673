import localStorageService from "./localStorageService";
import api from './api';
import history from "../helpers/history.js";
class JwtAuthService {
  loginWithEmailAndPassword = (email, password) => {
      return api.postData("login",
      { 
        email: email,
        password: password
      })
      .then((response) => { 
            if (response.data.success) {
              this.setSession(response.data.userData.api_token);
              this.setUser(response.data);
              return response.data;
            }
            else{
              return response;
            }
      })
      .catch((response) => {
        return "error"
      });
  };

  logout = async () => {
    this.removeUser().then(()=>{
      this.setSession(null);
    });

    history.push({ pathname: "/login" });
      
    window.location.reload();
  }


  // Set token to all http request header, so you don't need to attach everytime
  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      api.axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
      api.axiosInstance.defaults.headers.common['Accept'] = 'application/json';
    } else {
      localStorage.removeItem("jwt_token");
      delete api.axiosInstance.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {    
    localStorageService.setItem("auth_user", user);
  }

  getUser = () => {    
    return localStorageService.getItem("auth_user");
  }

  // Remove user from localstorage
  removeUser = async () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
