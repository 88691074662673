import { combineReducers } from 'redux';
import { UserReducer } from './reducers/UserReducer';
import { LoginReducer } from './reducers/LoginReducer';
import { SocketReducer } from './reducers/SocketReducer';
import { errorReducer } from './reducers/errorReducer';

const allReducers = {
    user        : UserReducer,
    login       : LoginReducer,
    socket      : SocketReducer,
    errors      : errorReducer
};

export const rootReducer = combineReducers(allReducers);