import { LOGIN_SUCCESS,LOGIN_ERROR,LOGIN_LOADING,RESET_PASSWORD,LOGOUT_SUCCESS } from "../actions/LoginActions";

const initialState = {
  success: false,
  loading: false,
  error: false
};

export const LoginReducer = function(state = initialState, action) {
  switch (action.type) {
    case LOGIN_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        success: false,
        loading: false
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }
    case LOGIN_ERROR: {
      return {
        success: false,
        loading: false,
        error: true
      };
    }
    default: {
      return state;
    }
  }
};