import { notification } from 'antd';
import { Socket } from 'socket.io-client';

export const SOCKET_ERROR  = "LOGIN_ERROR";
export const SOCKET_SUCCESS = "LOGIN_SUCCESS";
export const SOCKET_LOADING = "LOGIN_LOADING";

export let defaultSocket = null;

export const loadInitialDataSocket = (socket,userData) => {
     
	return (dispatch) => {


    if (!socket) return;
    
    defaultSocket = socket;

    /*
    socket.on('initialList',(res)=>{
		   console.dir(res)
		   dispatch(initialItems(res))
	});
    */
          
    socket.on('connect', () => {
        //setSocketConnected(socket.connected);
        socket.emit('adduser', { company: userData.company_id, mail: userData.email,userInfo : userData });
    });
  
    socket.on('disconnect', () => {
        //setSocketConnected(socket.connected);
    });
  
    socket.on('message', function(data) {
        console.log("Message",data);
  
        notification.info({
          message: data.fromName,
          description:  data.message,
          placement : "bottomRight",
        });
    });

    socket.on('status', function(data) {
        console.log("Status",data);
  
        var statusText = "";
    	if(data.status === 1){
    		statusText = data.name + " iş alımını açtı.";
    	}
    	else
    	{
    		statusText = data.name + " iş alımını kapattı.";
    	}

        notification.info({
          message: "İş Alımı",
          description:  statusText,
          placement : "bottomRight",
        });
    });

    socket.on('messageHistory',function(data){
        console.log("Messages History",data);
    });
    
  
    socket.on('userJoin', function (message) {
        console.log("userJoin",message);
    
        if(message !== userData.email){
            notification.info({
                message: `Kullanıcı Giriş Yaptı!`,
                description: message,
                placement : "bottomRight",
              });
        }
    });
	}
}

export const sendMessage = (data) => {
    defaultSocket.emit('message',data);  
}

export const sendSocketMessage = (type,data) => {
    console.log("SendSocketMessage",data);
    defaultSocket.emit(type, data);
}

export const addNewItemSocket = (socket,id,item) => {
	return (dispatch) => {
		let postData = {
				id:id+1,
				item:item,
				completed:false
		     }
        defaultSocket.emit('addItem',postData)		
	}	
}

export const markItemCompleteSocket = (socket,id,completedFlag) => {
	return (dispatch) => {
		let postData = {
				id:id,
				completed:completedFlag
		     }
        defaultSocket.emit('markItem',postData)
	}	
}