import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import HomePage from './components/HomePage'
import Tasks from './pages/Tasks/Tasks'
import Invoices from './pages/Invoices/Invoices'
import NewInvoice from './pages/Invoices/NewInvoice'
import Customers from './pages/Customers/Customers'
import Accounts from './pages/Accounts/Accounts'
import Users from './pages/Users/Users'
import Items from './pages/Items/Items'
import page404 from './pages/page404'
import LoginPage from './pages/User/LoginPage'
import LostPasswordPage from './pages/User/LostPasswordPage'
import RegisterPage from './pages/User/RegisterPage'
import Warehouses from './pages/Warehouse/Warehouses'
import Payment from './pages/payment'
import Settings from './pages/Settings'
import { Router, Switch, Route, Redirect,useLocation,useParams } from "react-router-dom";
import history from './helpers/history';
import Inventory from './pages/Inventory/inventory';
import TemplateNew from './components/TemplateNew'
import "./i18n";
import { notification, Space, Button } from 'antd';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

function App() {
    const login     = useSelector(state => state.login);
    const userData  = useSelector(state => state.user.userData);
    let location = ''
    const [api, contextHolder] = notification.useNotification();
    const key = `open${Date.now()}`;
    
    useEffect(() => {

      if(login.success){

        window.Pusher = Pusher;

        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: 'd4b9af39550bd7832778',
            wsHost: 'erp-api.aybimyazilim.com',
            forceTLS: true,
            cluster : 'mt1',
            auth:{
                headers: {
                    Authorization: 'Bearer '+ userData.api_token, 
                }
            },
        });
  
        
        window.Echo.channel('notification-event')
        .listen('NotificationEvent', (result) => {
          console.log(result)
          api['info']({
              message: result.title,
              description: result.message,
              placement : "bottomRight",
              btn:  (
                <Space>
                  <Button type="primary" size="small" onClick={() => api.destroy(key)}>
                    Confirm
                  </Button>
                </Space>
              ),
              key
          });
        
        });

      }

    }, [login.success]);

    return (
      <>
      {contextHolder}
          <Router history={history}>
            <Switch>
                <Route path="/login" component={LoginPage} />
                <Route path="/lost-password" component={LostPasswordPage} />
                <Route path="/register" component={RegisterPage} />

                {
                  login.success ? (
                    <>
                      <TemplateNew sideBar={ location == "tasks" ? true : false} >
                        <Route exact path="/" component={HomePage} />
                        <Route path="/tasks" component={Tasks} />
                        <Route path="/invoices/new" component={NewInvoice} />
                        <Route path="/invoices/edit/:id" component={NewInvoice} />
                        <Route path="/invoices" component={Invoices} />
                        <Route path="/customers" component={Customers} />
                        <Route path="/accounts" component={Accounts} />
                        <Route path="/items" component={Items} />
                        <Route path="/inventory" component={Inventory} />
                        <Route path="/users" component={Users} />
                        <Route path="/warehouses" component={Warehouses} />
                        <Route path="/payment" component={Payment} />
                        <Route path="/settings" component={Settings} />
                      </TemplateNew>
                    </>
                  )
                  :
                  <Redirect to="/login" />
                }

                <Route path="*" component={page404} />
            </Switch>
          </Router>
      </>
    );

}

export default App;