import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import { Redirect } from 'react-router-dom';
import history from "../../helpers/history.js";
import '../../index.css';
import { Form, Input, Button, Checkbox, Card, Col, Row, Spin, Alert } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const LostPasswordPage = () => {
  const login = useSelector(state => state.login);
  const dispatch = useDispatch();

  const colProps = { xs: 24, sm: 12, md: 8 };

  const onFinish = (values) => {
    //console.log('Received values of form: ', values);
    dispatch(loginWithEmailAndPassword(values))
  };

  return (
    login.success ? <Redirect to='/' /> :
    <div style={{ backgroundImage: `url('/assets/img/login.jpg')` }}>
      <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <div className="container mx-auto">
          <div className="flex justify-center px-6 my-12">
              <div className="w-full xl:w-3/4 lg:w-11/12 flex">
                  <div
                      className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
                      style={{ backgroundImage: `url('assets/img/forgot_pass.jpg')` }}
                  ></div>
                  <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                      <Spin tip="Giriş yapılıyor..." spinning={login.loading}>
                      <div class="px-8 mb-4 text-center">
                        <h3 className="pt-4 text-2xl text-center">Şifremi Unuttun</h3>
                        <p class="mb-4 text-sm text-gray-700">
                          We get it, stuff happens. Just enter your email address below and we'll send you a
                          link to reset your password!
                        </p>
                      </div>
                      <Form
                          name="login_form"
                          className="login-form"
                          initialValues={{
                            remember: true,
                            email: "sametpoyraz@live.com",
                            password: "sp8985"
                          }}
                          onFinish={onFinish}
                        >

                       <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded">
                          {login.error &&
                              <Alert message="Giriş Başarısız..." type="error" showIcon />
                          }

                          <label className="block mb-2 text-sm font-bold text-gray-700" htmlFor="login_form_email">
                             E-Posta Adresi
                          </label>

                          <Form.Item
                            name="email"
                            rules={[
                              {
                                type: 'email',
                                message: 'Lütfen e-posta adresinizi uygun formatta giriniz!',
                              },
                              {
                                required: true,
                                message: 'Lütfen e-posta adresi giriniz!',
                              },
                            ]}
                            hasFeedback
                          >
                            <Input 
                              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline" 
                              prefix={<UserOutlined className="site-form-item-icon" />} 
                              placeholder="E-Posta" 
                            />
                          </Form.Item>

                          <div className="mb-6 text-center">
                  
                              <button
                                  className="w-full px-4 py-2 font-bold text-white bg-red-500 rounded-full hover:bg-red-700 focus:outline-none focus:shadow-outline"
                                  type="submit"
                              >
                                  Şifremi Yenile
                              </button>
                          </div>
                          <hr className="mb-6 border-t" />

                          <div className="text-center">
                              <a
                                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                  onClick={ () =>  history.push('register') }
                              >
                                 Hesap Oluştur
                              </a>
                          </div>

                          <div className="text-center">
                              <a
                                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                  onClick={ () =>  history.push('login') }
                              >
                                  Hesabınız var mı ? Giriş Yapın
                              </a>
                          </div>
                      </div>
                      </Form>
                      </Spin>
                  </div>
              </div>
          </div>
      </div>

      </Row>
  </div>


  );
}

export default LostPasswordPage;
