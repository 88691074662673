import { Select } from "antd";
import { debounce } from "../helpers";
import React, { ReactNode, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import api from "../services/api";

const AybimSearch = ({
  entity,
  currentValue,
  className = "",
  renderOptions,
  stringifyJSON = false,
  ...rest
}) => {

  const [searchResults,setSearchResults] = useState([]);

  let selectedValues = [];

  if (Array.isArray(currentValue)) {
    selectedValues = currentValue;
  } else if (Object.keys(currentValue || {}).length > 0) {
    selectedValues = [currentValue];
  }

  useEffect(()=>{
    selectedValues && searchValue({ q: selectedValues })
  },[])

  const { isLoading, mutate : searchValue  } = useMutation(params => { return  api.getQueryData({url: entity, params}) } ,  {
    onSuccess: async (data, variables, context) => {
        setSearchResults(data)
    }       
  })

  const searchEntity = (searchTerm) => {
    searchTerm && searchValue({ q: searchTerm })
  };

  let filteredSearchResults =
    searchResults?.filter(
      (item) => selectedValues.findIndex((value) => stringifyJSON  ? value === JSON.stringify(item) : value.id === item.id, ) === -1,
    ) || [];

  if (stringifyJSON) {
    filteredSearchResults = filteredSearchResults?.map((item) => JSON.stringify(item)) || [];
  }

  return (
    <Select
      {...rest}
      onSearch={ (newValue) => {
        if (newValue.length > 3) {
          debounce(searchEntity(newValue), 300)
        }
      }
      }
      //onFocus={() => searchEntity("")}
      filterOption={false}
      loading={isLoading}
      className={className}
    >
      {renderOptions(selectedValues)}
      {renderOptions(filteredSearchResults)}
    </Select>
  );
};

export default withTranslation()(AybimSearch);
