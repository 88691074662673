import React from 'react'
import { Result, Spin } from 'antd';
import { Card, Row, Col } from 'antd';
import { useQuery } from "react-query";
import api from '../services/api';
import TemplateNew from '../components/TemplateNew'

const Payment = () => {
    const { data, isLoading, error, refetch, isRefetching } = useQuery('purchaseForm',  () => api.getQueryData({url: 'purchase' }));
  
    return (
        <Spin tip="Yükleniyor..." size="large" spinning={(isLoading || isRefetching)} style={{ color: '#67be23' }}>
            <Card title="Ödeme Sayfası">
                <Row style={{  height: 'calc(90vh - 130px)' }}>
                    <Col xl={16} lg={16} md={24} sm={24} xs={24}>

                        <iframe srcDoc={data} width={'100%'} height={'100%'} style={{ border:'none' }}></iframe>
                        
                    </Col>
                    <Col xl={8} lg={16} md={24} sm={24} xs={24}>
                        <Result
                            status="403"
                            title="Ödeme Sayfası"
                            subTitle="Sol taraftan ödemenizi yaparak, uygulamayı kullanmaya devam edebilirsiniz."
                        />
                    </Col>
                </Row>
            </Card>
        </Spin>
    )
}

export default Payment
