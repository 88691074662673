import React, { useState, useLayoutEffect } from 'react'

import TemplateNew from '../../components/TemplateNew'
import {
    Tag, Table, Spin, Layout, Modal, Form, Checkbox, Button, Select, DatePicker,Input, Tabs, Row, Col, 
    Badge, InputNumber, Typography, Radio, Space, Card
} from 'antd';
import { CarryOutOutlined, MessageOutlined, InfoCircleOutlined, StockOutlined, UserAddOutlined,SearchOutlined } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react'
import { CirclePicker } from 'react-color'

import moment from 'moment'
import AybimTable from '../../components/AybimTable'
import FilterDropdown from '../../components/FilterDropdown'
import { useQuery, useMutation } from "react-query";
import api from '../../services/api';

const Warehouses = () => {
    const { Column } = Table;
    const { Content } = Layout;
    const { TabPane } = Tabs;
    const { Title } = Typography;
    const { Option } = Select;

    const [newCustomerForm] = Form.useForm();

    const [customerModal, setCustomerModal] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const dateFormat = 'DD-MM-YYYY';
    const [marker, setMarker] = useState([]);


    const formatDate = (date) => {
        return moment(date).format('D.MM.YYYY HH:mm')
    }

    const saveCustomerData = (customerData) => {
        console.log(customerData);
    }

    return (
        <div>
                <Card
                    title="Depolar"
                    extra={<Button 
                        type="primary" 
                        icon={<UserAddOutlined />} 
                        shape="round"  
                        onClick={() => { 
                            setCustomerModal(true); 
                            setCustomerData([]);  
                            newCustomerForm.resetFields(); 
                        }}>Depo Ekle</Button>}
                >

                        <AybimTable
                            url = "warehouses/list"
                            tableName = "warehouseList"
                            onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {  
                                            newCustomerForm.resetFields()
                                            newCustomerForm.setFieldsValue(record)
                                            setCustomerModal(true); 
                                            setCustomerData(record)
                                        },
                                    };
                            }}
                        >
                                <Column title="Sıra No" dataIndex="id" sorter="true" width={50}  />
                                <Column title="Depo Adı" dataIndex="warehouse_name" sorter="false" filterDropdown={ (props) => <FilterDropdown  {...props}/> } width={150} />
                                <Column title="Telefon" dataIndex="phone_number" key="phone_number" sorter="true" filterDropdown={ (props) => <FilterDropdown  {...props}/> }  width={150} />
                        </AybimTable>
                </Card>

            {/* Depo Detayları */}
            <Modal
                title={ 'Depo Bilgileri' }
                centered
                destroyOnClose={true}
                open={customerModal}
                cancelText="Kapat"
                okText="Kaydet"
                onOk={() => {
                    newCustomerForm.submit()
                }}
                onCancel={() => setCustomerModal(false)}
                width={'40%'}
                bodyStyle={{height: '250px' }}
            >
                    <Form form={newCustomerForm} onFinish={(values)=> saveCustomerData(values) } onFieldsChange={(e) => setCustomerData(newCustomerForm.getFieldsValue()) } >
                                <Form.Item name="id" style={{ display: 'none' }} initialValue="0">
                                    <Input type="text" />
                                </Form.Item>
                                <Row gutter={[16, 16]} style={{ marginLeft: 10 }}>
                                    <Col span={12}>
                                        
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Depo Adı :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="warehouse_name" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input  />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Telefon :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="phone_number" rules={[{ required: true, message: 'Lütfen vergi no giriniz!' }]} >
                                                    <InputNumber max="9999999999" style={{ width: 200 }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col span={12}>
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Tipi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="type" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Select>
                                                        <Option value={0}>Müşteri</Option>
                                                        <Option value={1}>Tedarikçi</Option>
                                                        <Option value={2}>Müşteri&Tedarikçi</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                    </Form>
            </Modal>


        </div>
    )
}

export default Warehouses