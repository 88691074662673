import React, { useState, useEffect, useLayoutEffect } from 'react'
import TemplateNew from '../../components/TemplateNew'

import { Modal, Table, Form, Checkbox, Button, Select, DatePicker, Tag, Menu, Dropdown, Input, Spin, Tabs, Row, Col, Card, Image,Timeline, Avatar, Tooltip, Popconfirm, Badge,
    message, Upload, Typography, List, Drawer, Space, Divider, Switch, Empty
} from 'antd'

import { Comment } from '@ant-design/compatible';

import {
    OrderedListOutlined, SettingFilled, EditOutlined, EllipsisOutlined, FolderOpenOutlined, FolderAddOutlined, FilterOutlined,CarryOutOutlined, MessageOutlined, PlusOutlined,
    InfoCircleOutlined, StockOutlined, DeleteOutlined,EnvironmentOutlined, FileImageOutlined, DragOutlined
} from '@ant-design/icons'

import FilterDropdown from '../../components/FilterDropdown'

import localStorageService from "../../services/localStorageService";
import locale from 'antd/es/date-picker/locale/tr_TR'
import GoogleMapReact from 'google-map-react'
import { useDispatch, useSelector } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment'
import trLocale from 'moment/locale/tr';
import { CirclePicker } from 'react-color'
import  { NumericFormat,PatternFormat }  from "react-number-format";

import WorkerMarker from './WorkerMarker'
import JobMarker from './JobMarker'
import { BASE_URL,API_BASE_URL } from "../../services/ApiConstant"
import { sendMessage } from '../../redux/actions/SocketActions'

import dayjs from 'dayjs';

import { useQuery, useMutation } from "react-query";
import api from '../../services/api';

import ReactDragListView from "react-drag-listview";
import ImgCrop from 'antd-img-crop';
import TaskStock from './Components/taskStock';

const Tasks = () => {
    //States
    const [tasksData,setTasksData]                          = useState([]);
    const [loadTaskData, setLoadTaskData]                   = useState(false);
    const [mapCenter, setMapCenter]                         = useState({ lat: 60.65, lng: 30.09 });
    const [mapZoom]                                         = useState(8);
    const [mapTrafficLayer, setMapTrafficLayer]             = useState(false);
    const [mapTrafficLayerType, setMapTrafficLayerType]     = useState([]);
    const [mapDarkMode, setMapDarkMode]                     = useState(false);
    const [mapShowLocationName, setMapShowLocationName]     = useState(false);
    const [mapStyle, setMapStyle]                           = useState([]);

    const [selectedColor, setSelectedColor]                 = useState('#fff')
    const [activePage, setActivePage]                       = useState('default');
    const [filterModal, setfilterModal]                     = useState(false);
    const [customFieldModal, setcustomFieldModal]           = useState(false);
    const [jobDetailModal, setjobDetailModal]               = useState(false);
    const [addNewModal, setAddNewModal]                     = useState(false);
    const [workstepModal, setWorkstepModal]                 = useState(false);
    const [changeJobStatusModal, setChangeJobStatusModal]   = useState(false);
    const [assignWorkerModal, setAssignWorkerModal]         = useState(false);
    const [workerDetailModal, setWorkerDetailModal]         = useState(false);
    const [chatModal, setChatModal]                         = useState(false);

    const [responsibleUser,setResponsibleUser]              = useState(0)
    const [jobStatus,setJobStatus]                          = useState(0)

    const [selectedJobId, setSelectedJobId]                 = useState([]);
    const [selectedJobData, setSelectedJobData]             = useState([]);
    const [selectedWorkerData, setSelectedWorkerData]       = useState([]);
    const [selectedCustomFieldData, setSelectedCustomFieldData] = useState([]);
    const [newJobData, setnewJobData]                       = useState([]);
    const [filterData, setFilterData]                       = useState();
    const [comment,setComment]                              = useState('');
    const [chatUser,setChatUser]                            = useState('');
    const [customerList, setCustomerList]                   = useState([]);
    const [searchTerm,setSearchTerm]                        = useState("")

    const [newJobForm]          = Form.useForm();
    const [newFieldForm]        = Form.useForm();

    //Redux
    //const tasksData = useSelector(state => state.tasks);
    const userData  = useSelector(state => state.user.userData);

    //Antd Definitions
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const { TabPane } = Tabs;
    const { TextArea } = Input;
    const { Column } = Table;
    const { Meta } = Card;
    const { Title } = Typography;

    const { data, isLoading, error, refetch, isRefetching } = useQuery('taskList',  () => api.getQueryData({url: 'tasks/list', params: filterData, method: 'GET' }),{ enabled: loadTaskData });

    //get job detail
    const {         
            data        : taskData, 
            isLoading   : taskDetailLoading, 
            error       : taskDetailerror, 
            refetch     : refetchTaskDetail, 
            isRefetching: taskisRefetching 
    } = useQuery(["taskDetail", { selectedJobId }], () => api.getQueryData({url: 'tasks/detail', params: { jobId: selectedJobId } }), {enabled: selectedJobId > 0});

    //get customer by name
    const customer = useMutation(params => { return  api.getQueryData({url: 'customers/search', params}) } ,  {
            onSuccess: async (data, variables, context) => {
                setCustomerList(data)
            } 
    })

    //change job responsible
    const changeResponsible = useMutation(params => { return  api.getQueryData({url: 'tasks/assignWorker', params}) } ,  {
        onSuccess: async () => {
            setAssignWorkerModal(false)
            refetchTaskDetail()
            message.success('İş sorumlusu değiştirildi.')
        } 
    })

    //change job status
    const changeStatus = useMutation(params => { return  api.getQueryData({url: 'tasks/changeJobStatus', params}) } ,  {
        onSuccess: async () => {
            setChangeJobStatusModal(false)
            refetchTaskDetail()
            message.info('İş durumu değiştirildi.')
        } 
    })

    //delete job
    const deleteJob = useMutation(params => { return  api.getQueryData({url: 'tasks/deleteJob', params}) } ,  {
        onSuccess: async () => {
            refetch()
            setjobDetailModal(false)
            setSelectedJobId(0)
            message.info('İş silindi.')
            
        } 
    })

    //add Note
    const addNote = useMutation(params => { return  api.getQueryData({url: 'tasks/addNote', params}) } ,  {
        onSuccess: async () => {
            refetchTaskDetail()
            setComment('')
            message.info('Not eklendi.')
        } 
    })

    //save job
    const saveJob = useMutation(params => { return  api.getQueryData({url: 'tasks/save', params}) } ,  {
        onSuccess: async (data, variables, context) => {
            console.log(data.message)
            if(data.success){
                setAddNewModal(false)
                message.success('İş kayıdı yapıldı ve bildirim gönderildi.')
                refetch()
            }
            else{
                message.error(data.message)
            }
        } 
    })

    //save field
    const saveField = useMutation(params => { return  api.getQueryData({url: 'tasks/saveCustomField', params}) } ,  {
        onSuccess: async (data, variables, context) => {
            if(data.success){
                refetch()
                setcustomFieldModal(false)
                message.info('Özel alan eklendi.')
            }
            else{
                message.error(data.message)
            }
        } 
    })

    useEffect(() => {
        setTasksData(data?.result)
    }, [data]);

    const strToArr = function (str) {
        return str.split(',');
    }

    useEffect(() => { // Map Settings
        var mapStyles = [];
        if (mapDarkMode) {
            mapStyles.push(
                { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
                { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
                { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
                {
                    featureType: 'administrative.locality',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }]
                },
                {
                    featureType: 'poi',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'geometry',
                    stylers: [{ color: '#263c3f' }]
                },
                {
                    featureType: 'poi.park',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#6b9a76' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry',
                    stylers: [{ color: '#38414e' }]
                },
                {
                    featureType: 'road',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#212a37' }]
                },
                {
                    featureType: 'road',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#9ca5b3' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry',
                    stylers: [{ color: '#746855' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'geometry.stroke',
                    stylers: [{ color: '#1f2835' }]
                },
                {
                    featureType: 'road.highway',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#f3d19c' }]
                },
                {
                    featureType: 'transit',
                    elementType: 'geometry',
                    stylers: [{ color: '#2f3948' }]
                },
                {
                    featureType: 'transit.station',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#d59563' }]
                },
                {
                    featureType: 'water',
                    elementType: 'geometry',
                    stylers: [{ color: '#17263c' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.fill',
                    stylers: [{ color: '#515c6d' }]
                },
                {
                    featureType: 'water',
                    elementType: 'labels.text.stroke',
                    stylers: [{ color: '#17263c' }]
                }
            );
        }
        if (mapShowLocationName) {
            mapStyles.push({
                featureType: "all",
                elementType: "labels",
                "stylers": [{
                    "visibility": "off"
                }]
            });
        }

        setMapStyle(mapStyles);

        if (mapTrafficLayer)
            setMapTrafficLayerType(['TrafficLayer'])
        else
            setMapTrafficLayerType([])

        localStorageService.setItem("map_style", { darkMode: mapDarkMode, showLocation: mapShowLocationName, trafficLayer: mapTrafficLayer });

    }, [mapDarkMode, mapShowLocationName, mapTrafficLayer]);

    useLayoutEffect(() => {
        let localFilterData = localStorageService.getItem("filter_data");

        if(localFilterData!=null){
            setFilterData(localFilterData)
        }
        else{
            Promise.resolve()
            .then(() => { setFilterData( { created_at: { startDate: null, endDate: null }, fields: {} })  })
        }

        setnewJobData({
            type: 1,
            customer: {},
            images: [],
            customer_id: 0,
            operation_id: 0
        });

        navigator.geolocation.getCurrentPosition(function (position) {
            console.log(position);
            setMapCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });

        });

        let mapStyle = localStorageService.getItem("map_style");
        if(mapStyle){
            setMapDarkMode(mapStyle.darkMode);
            setMapShowLocationName(mapStyle.showLocation);
            setMapTrafficLayer(mapStyle.trafficLayer);
        }

        setLoadTaskData(true)

        moment.locale('tr');
    }, [])


    const refreshData = () => {
        localStorageService.setItem("filter_data",filterData);
        refetch()
    }


    useEffect(() => {
        newJobForm.setFieldsValue(taskData?.operation)

        customer.mutate({ q: taskData?.operation?.customer?.id })

    }, [taskData, newJobForm]);
    
    const editJob = (jobData) => {
        setSelectedJobId(jobData.operation_id)
        setAddNewModal(true);
    }

    const copyJob = () => {

    }

    const updateJob = () => {
        setAddNewModal(true);
    }

    const openWorkerDetail = (workerData) => {
        setWorkerDetailModal(true);
    }

    const openCustomFieldForm = (data) => {
        setcustomFieldModal(true);
    }

    const showMarker = (key, type) => {
        if (type == "job") {
            let allTask = tasksData?.jobs;
            let newData = allTask.filter(job => key == job.operation_id);
            setMapCenter({ lat: parseFloat(newData[0].lat), lng: parseFloat(newData[0].lng) });
            setSelectedJobData(newData);
        }
        else {
            let allWorker = tasksData?.workers;
            let newData = allWorker.filter(worker => key == worker.id);
            setMapCenter({ lat: parseFloat(newData[0].lat), lng: parseFloat(newData[0].lng) });
            setSelectedWorkerData(newData);
        }

    }
    
    const formatDate = (date) => {
        return moment(date).locale("tr").format('LLL')
    }

    const openChatModal = (user) => {
        console.log(user);

        const detail = {
            from :  'gulsum@hotmail.com',
            to :    user.email
        };

        //sendSocketMessage.emit('messageHistory', detail)

        setChatUser(user);
        setChatModal(true);
    }

    const JobPane = ({ job }) => (
        <Card style={{ marginTop: 2, padding: 8 }} bodyStyle={{ padding: "0", marginBottom: 4 }} key={job.operation_id} actions={[
            <FolderOpenOutlined key="setting" onClick={(e) => { setSelectedJobId(job.operation_id); setjobDetailModal(true) } } />,
            <EditOutlined key="edit" twoToneColor="#eb2f96" onClick={(e) => editJob(job)} />,
            <EnvironmentOutlined key="ellipsis" onClick={(e) => showMarker(job.operation_id, "job")} />,
        ]}>
            <Row>
                <Col span="24">{formatDate(job.start_time)}</Col>
            </Row>
            <p className="tail-font-weight-bold tail-mt-2 tail-uppercase"><i className="la la-male" /> <b>{job.customer?.customer_name}</b></p>

            <p className="tail-mt-2">{job?.description}</p>
            <Tag className="tail-text-muted tail-float-left"> {job?.worker?.name}</Tag>
            {job.status &&
                <small className="tail-text-muted tail-float-right" ng-if="job.status">
                    <Tag color={job?.status?.color}>{job?.status?.name}</Tag>
                </small>
            }
        </Card>
    )

    const workerPane = (worker) => {
        return (
            <>
                <Card style={{ marginTop: 2, padding: 5,cursor: 'pointer' }} onClick={() => showMarker(worker.id, "worker")} bodyStyle={{ padding: 14 }} bordered={true} key={worker.id}>
                    <Meta
                        avatar={
                            <Avatar size={64} src={worker.profile_photo ? BASE_URL + '/userphoto/' + worker.profile_photo : 'assets/img/avatars/nobody.jpg'} />
                        }
                        title={[<> {worker.name}  <Badge style={{ color: '#fff', backgroundColor: '#736cc7' }} count={tasksData?.jobs?.filter(job => job.worker_id  == worker.id ).length } /> </>]}
                        description={ worker?.email + '\n' + worker?.phone }
                    />
                </Card>
            </>
        )
    }

    const mapMenuItems = [
      {
          key: 'customFields',
          label: "Özel Alanlar",
      },
      {
          key: 'workSteps',
          label: 'İş Adımları',
      },
      {
          key: 'trafficlayer',
          label: (<Checkbox checked={mapTrafficLayer} onChange={(e) => setMapTrafficLayer(e.target.checked)}>Trafik Bilgisi</Checkbox>),
      },
      {
          key: 'showLocation',
          label: (<Checkbox checked={mapShowLocationName} onChange={(e) => setMapShowLocationName(e.target.checked)}>Lokasyon Gizle</Checkbox>),
      },
      {
          key: 'mapDarkMode',
          label: (<Checkbox checked={mapDarkMode} onChange={(e) => setMapDarkMode(e.target.checked)}>Karanlık Harita</Checkbox>),
      }
    ];

    const handleMenuClick = (e) => {
        if (e.key === 'customFields') 
            setActivePage('customFields')
        if (e.key === 'workSteps')
            setActivePage('workSteps')
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    }
    
    const onDragEnd = (fromIndex, toIndex, type) => {
        let index = 0
        console.log("fromindex",fromIndex)
        console.log("toındex",toIndex)
        /* IGNORES DRAG IF OUTSIDE DESIGNATED AREA */
        if (toIndex < 0) return;
    
        /* REORDER PARENT OR SUBLIST, ELSE THROW ERROR */
        if (type === "LIST-ITEM") {
          const categories = reorder(data, fromIndex, toIndex);
          //return setData(categories);
        } else if (type === "SUBLIST-ITEM") {
          const subList = reorder(data[index].subList, fromIndex, toIndex);
          const categories = JSON.parse(JSON.stringify(data));
          categories[index].subList = subList;
          //return setData(categories);
        } else return new Error("NOT A VALID LIST");
    };

    const CustomerSelectNotFoundContent = ({
        customer,
        addCustomer,
      }) => {
        return (
          <div className="s-main-font s-main-text-color tail-text-center tail-py-4 tail-px-2">
            <div className="s-semibold tail-text-gray-600 tail-text-lg tail-truncate">
              {customer}
            </div>
            <div>Yardım</div>
            <div className="tail-text-gray-500 s-semibold tail-my-3">
              Veyaz
            </div>
            <Button size="small" type="primary" onClick={addCustomer}>
              Ekle
            </Button>
          </div>
        );
      };

    const addCustomer = () => {

    }

    return (
        <>
                {/* Harita Görünümü */}
                {(activePage === 'default') &&
                <Spin tip="Yükleniyor..." spinning={(isLoading || isRefetching)}>
                        <Row>
                            <Col xs={24} xl={5}> 
                                <Card title="İş Listesi" bordered={false} style={{ width: '100%' }} bodyStyle={{ padding: 3 }} headStyle={{ background: '#736cc7', color: '#fff' }}>
                                    
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab={<><Title level={3} style={{ textAlign: 'center' }}>{!isLoading && tasksData?.jobs?.filter(job => job.status === null || (job.status?.value !== 'complete' && job.status?.value !== 'cancel')).length}</Title>Devam Eden</>} key="1" >
                                            <Scrollbars style={{ height: 'calc(70vh)' }}>
                                                    {tasksData?.jobs?.filter(job => job.status === null || (job.status?.value !== 'complete' && job.status?.value !== 'cancel')).map(job =>
                                                        <JobPane job={job} key={job.operation_id}/>
                                                    )}
                                            </Scrollbars>
                                        </TabPane>
                                        <TabPane tab={<><Title level={3} style={{ textAlign: 'center' }}>{!isLoading && tasksData?.jobs?.filter(job => job.status !== null && (job.status?.value === 'cancel')).length}</Title>İptal işler</>} key="2">
                                            <Scrollbars style={{ height: 'calc(70vh)', }}>
                                                    {tasksData?.jobs?.filter(job => job.status !== null && (job.status?.value === 'cancel')).map(job =>
                                                        <JobPane job={job} key={job.operation_id}/>
                                                    )}
                                            </Scrollbars>
                                        </TabPane>
                                        <TabPane tab={<><Title level={3} style={{ textAlign: 'center' }}>{!isLoading && tasksData?.jobs?.filter(job => job.status !== null && (job.status?.value === 'complete')).length}</Title>Biten İşler</>} key="3">
                                            <Scrollbars style={{ height: 'calc(70vh)', }}>
                                                    {tasksData?.jobs?.filter(job => job.status !== null && (job.status?.value === 'complete')).map(job =>
                                                        <JobPane job={job} key={job.operation_id}/>
                                                    )}
                                            </Scrollbars>
                                        </TabPane>
                                    </Tabs>
                                </Card>
                            </Col>
                            <Col xs={24} xl={14} id="aybimMap">
                                <ul className="tail-list-none tail-p-0 tailmt-1 tail-absolute tail-z-50">
                                    <div className="tail-float-left tail-ml-2">
                                        <Button type="primary" onClick={() => setfilterModal(true)} icon={<FilterOutlined />}>Süz </Button>
                                    </div>
                                    <div className="tail-float-left tail-ml-2">
                                        <Button type="primary" onClick={() => { tasksData.jobDetail = []; newJobForm.resetFields(); setSelectedJobId(0); setAddNewModal(true) }} icon={<FolderAddOutlined />}>İş Ekle</Button>
                                    </div>
                                    <div className="tail-float-left tail-ml-2">
                                        <Button type="primary" onClick={() => setActivePage('list')} icon={<OrderedListOutlined />}>Liste</Button>
                                    </div>
                                </ul>

                                <div className="tail-absolute tail-bottom-0 tail-left-0 tail-z-10 tail-m-3">
                                    <Dropdown menu={{items: mapMenuItems,onClick: handleMenuClick}}>
                                        <Button type="primary" shape="circle" icon={<SettingFilled />} size="large" />
                                    </Dropdown>
                                </div>


                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyD8iQZahLY5FVfUWlVrb4VGk-Nr72t8VMY" }}
                                    center={mapCenter}
                                    defaultCenter={{ lat: 60.65, lng: 30.09 }}
                                    initialCenter={mapCenter}
                                    defaultZoom={mapZoom}
                                    onChange={(args) => {
                                        const { center: newCenter } = args;
                                        setMapCenter(newCenter);
                                    }}
                                    options={{ scrollwheel: true, styles: mapStyle }}
                                    layerTypes={mapTrafficLayerType}
                                >
                                    {tasksData?.jobs?.map(jobData => (
                                        <JobMarker
                                            key={jobData.operation_id}
                                            lat={jobData.lat}
                                            lng={jobData.lng}
                                            show={selectedJobData}
                                            detail={(data)=> { setSelectedJobId(data.operation_id); setjobDetailModal(true) } }
                                            edit={editJob}
                                            data={jobData}
                                        />
                                    ))}

                                    {tasksData?.workers?.map(workerData => (
                                        <WorkerMarker
                                            key={workerData.id}
                                            lat={workerData.lat}
                                            lng={workerData.lng}
                                            show={selectedWorkerData}
                                            data={workerData}
                                            chatModal={ (user) => {
                                                openChatModal(user)
                                            }
                                            }
                                        />
                                    ))
                                    }
                                </GoogleMapReact>
                            </Col>
                            <Col xs={24} xl={5}>
                                <Card title="Çalışan Listesi" bordered={false} style={{ width: '100%' }} bodyStyle={{ padding: 3 }} headStyle={{ background: '#736cc7', color: '#fff' }}>
                                    <Tabs defaultActiveKey="1" onChange={(key) => console.log(key)}>
                                        <TabPane tab={<><Title level={4} style={{ textAlign: 'center' }}>{tasksData?.workers?.filter(worker => worker.status === 1).length}</Title>Aktif Kişi</>} key="1">
                                            <Scrollbars style={{ height: 'calc(70vh)' }}>
                                                    {tasksData?.workers?.filter(worker => worker.status === 1).map(worker => (
                                                        workerPane(worker) 
                                                    ))
                                                    }
                                            </Scrollbars>
                                        </TabPane>
                                        <TabPane tab={<><Title level={4} style={{ textAlign: 'center' }}>{tasksData?.workers?.filter(worker => worker.status === 0).length}</Title>Çevrimdışı Kişi</>} key="2">
                                            <Scrollbars style={{ height: 'calc(70vh)' }}>
                                                {tasksData?.workers?.filter(worker => worker.status === 0).map(worker => (
                                                    workerPane(worker)
                                                ))
                                                }
                                            </Scrollbars>
                                        </TabPane>
                                        <TabPane tab={<><Title level={4} style={{ textAlign: 'center' }}>{tasksData?.workers?.length}</Title>Tüm Kişi</>} key="3" >
                                            <Scrollbars style={{ height: 'calc(70vh)' }}>
                                                {tasksData?.workers?.map(worker => (
                                                    workerPane(worker)
                                                ))
                                                }
                                            </Scrollbars>
                                        </TabPane>
                                    </Tabs>
                                </Card>
                            </Col>
                        </Row>
                </Spin>
                }
                {/* Sohbet */}
                <Modal
                    title={"Sohbet - "+ chatUser.name }
                    centered
                    destroyOnClose={true}
                    open={chatModal}
                    cancelText="Kapat"
                    onCancel={() => setChatModal(false)}
                    width={'50%'}
                >

                            <>
                                <Comment
                                avatar={<Avatar src={ chatUser.profile_photo ? BASE_URL + '/userphoto/' + chatUser.profile_photo : 'assets/img/avatars/nobody.jpg' } alt={chatUser.name} />}
                                content={
                                    <>
                                    <Form.Item>
                                      <TextArea rows={4} />
                                    </Form.Item>
                                    <Form.Item>
                                      <Button htmlType="submit" type="primary">Mesaj Gönder</Button>
                                    </Form.Item>
                                    </>
                                }
                                />
                            </>

                    <div className="card-body" id="chatWrapper">
                        <div className="card" style={{ marginBottom: 0 }}>
                            <div className="d-flex flex fixed-height" id="3-col-wrapper">
                                <div className="center-content flex-fill bg-light">
                                    <div className="chat-contents">
                                        <div className="chat-content-wrap">
                                            <div className="chat-wrap-inner scroll-bottom" id="chatScroller" style={{ marginBottom: 0 }}>
                                                <div className="chats">
                                                    <div ng-repeat="message in messagesList" ng-if="(message.from_user == '{{ Auth::user()->email }}' && message.to_user == selectedPersonInfo.email) || (message.to_user == '{{ Auth::user()->email }}' && message.from_user == selectedPersonInfo.email)">
                                                        <div className="chat-line" ng-if="(messagesList[$index-1].time | date : 'dd.MM.y') < (message.time | date : 'dd.MM.y')">
                                                            <span className="chat-date" ng-bind="message.time | date : 'dd.MM.y'" />
                                                        </div>
                                                        <div className="chat chat-right" ng-if="message.from_user == '{{ Auth::user()->email }}' && message.to_user == selectedPersonInfo.email">
                                                            <div className="chat-body">
                                                                <div className="chat-bubble">
                                                                    <div className="chat-content">
                                                                        <p ng-bind="message.message" />
                                                                        <span className="chat-time" ng-bind="message.time | date : 'dd.MM.y HH:mm'" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left" ng-if="message.to_user == '{{ Auth::user()->email }}' && message.from_user == selectedPersonInfo.email">
                                                            <div className="chat-avatar">
                                                                <a href="profile.html" className="avatar">
                                                                    <img alt="" src="@{{ selectedPersonInfo.profile_photo ? 'userphoto/'+selectedPersonInfo.profile_photo : 'assets/img/avatars/nobody.jpg' }}" className="img-responsive img-circle" />
                                                                </a>
                                                            </div>
                                                            <div className="chat-body">
                                                                <div className="chat-bubble">
                                                                    <div className="chat-content">
                                                                        <p ng-bind="message.message" />
                                                                        <span className="chat-time" ng-bind="message.time | date : 'dd.MM.y HH:mm'" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ visibility: 'hidden' }}> $scope.lastMessageDate = message.time   </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer bg-white p-10">

                                        <div className="form-group m-10 p-0 p-l-75">
                                            <div className="input-group m-t-10">
                                                <label className="sr-only">Bir mesaj yaz</label>
                                                <input type="text" className="form-control border-none" placeholder="Bir mesaj yaz" ng-model="messageContent" ng-keypress="($event.which === 13)?sendMessage():0" />
                                                <Button type="button" className="btn btn-primary btn-rounded m-r-20" onClick={(e)=> sendMessage({ to : 'lina@lina.com', from : "sametpoyraz@live.com", message : "test"}) }  >
                                                    Gönder
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>
                {/* Liste Görünümü */}
                {(activePage === 'list') &&
                <Row>
                        <Col span={24}>
                            <Card title="İş Listesi" bordered={false}
                                extra={<ul className="tail-list-none tail-p-0 tail-mt-1 tail-z-50">
                                    <div className="tail-float-left tail-ml-2">
                                        <Button onClick={() => setfilterModal(true)} icon={<FilterOutlined />}>Süz </Button>
                                    </div>
                                    <div className="tail-float-left tail-ml-2">
                                        <Button onClick={() => { tasksData.jobDetail = []; setAddNewModal(true) }} icon={<FolderAddOutlined />}>İş Ekle</Button>
                                    </div>
                                    <div className="tail-float-left tail-ml-2">
                                        <Button onClick={() => setActivePage('default')} >Harita</Button>
                                    </div>
                                </ul>}
                                bodyStyle={{ padding: 10 }} headStyle={{ background: '#736cc7', color: '#fff' }}>

                                <Spin tip="Yükleniyor..." spinning={(isLoading || isRefetching)}>
                                <Table
                                    dataSource={tasksData?.jobs}
                                    pagination={{ pageSize: 25, pageSizeOptions: ['25', '50', '100', '250', '500'] }}
                                    rowKey={record => record.operation_id}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {  setSelectedJobId(record.operation_id) },
                                        };
                                    }}
                                    
                                    scroll={{ y: 'calc(100vh - 304px)', x: 1000 }}
                                    rowClassName={(record, index) => 
                                        index % 2 === 0 ? 'table-row-light' :  'table-row-dark'
                                    }
                                    size="middle"
                                    sticky={true}
                                >                                   
                                    <Column title="İş Tarihi" width={200} dataIndex="start_time" render={(date) => formatDate(date)} />
                                    <Column title="Takip No" dataIndex="operation_id" sorter="true" />
                                    <Column title="Müşteri Ad Soyad" width={200} dataIndex="customer" key="customer" render={(customer) => customer?.customer_name }/>
                                    <Column title="Çalışan" width={200} dataIndex="worker" key="worker" render={(worker) => worker?.name} sorter="true" />
                                    <Column title="İş Detayı" width={300} dataIndex="description" key="worker" />
                                    <Column title="Durum" dataIndex="status" render={(status) => <Tag color={status?.color}>{status?.name}</Tag>} />

                                    {tasksData?.custom_fields?.map(field => {
                                        return (<Column title={field.field_name} dataIndex="fields" render={(fields) => {
                                            return fields.map((fld) => {
                                                if (field.field_id === fld.field_id) {
                                                    return fld.value
                                                }
                                            })
                                        }
                                        } />)
                                    })
                                    }
                                    <Column title="İşlemler" render={(data) => (<><Tag color="blue" style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); editJob(data) }}>Düzenle</Tag> <Tag color="red" ><div onClick={(e) => e.stopPropagation()}><Popconfirm title="Sure to delete?" onConfirm={(e) => console.log("sil")}><a>Sil</a></Popconfirm></div></Tag></>)} />

                                </Table>
                                </Spin>
                            </Card>
                        </Col>
                </Row>
                }
                {/* Filtreleme */}
                <Modal
                    title="İş Filtresi"
                    centered
                    open={filterModal}
                    okText="Süz"
                    cancelText="İptal"
                    onOk={() => {
                        setfilterModal(false);
                        refreshData();
                    }}
                    onCancel={() => setfilterModal(false)}
                    width={550}
                >
                    <Row style={{ marginBottom: 8 }}>
                        <Col span={6}>Tarih</Col>
                        <Col span={18}>
                            <RangePicker
                                onChange={(dates, dateStrings) => {
                                    console.log(dateStrings)
                                    setFilterData({ ...filterData, created_at: { startDate: dateStrings[0], endDate: dateStrings[1] } })
                                }}
                                defaultValue={filterData?.created_at}
                                locale={locale}
                                presets={[
                                    { label: 'Dün', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
                                    { label: 'Bugün', value: [dayjs(),dayjs()] },
                                    { label: 'Yarın', value: [dayjs().add(1, 'd'), dayjs().add(1, 'd')] },
                                    { label: 'Gelecek 7 Gün', value: [ dayjs(), dayjs().add(6, 'd')] },
                                    { label: 'Gelecek 30 Gün', value: [dayjs(), dayjs().add(29, 'd')] },
                                    { label: 'Son 7 Gün', value: [dayjs().add(-6, 'd'), dayjs()] },
                                    { label: 'Son 30 Gün', value: [dayjs().add(-29, 'd'), dayjs()] },
                                    { label: 'Bu Ay', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                                    { label: 'Geçen Ay', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] } 
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 8 }}>
                        <Col span={6}>Müşteri</Col>
                        <Col span={18}>
                            <Input className="form-control" value={filterData?.customer} placeholder="Müşteri" onChange={(e) => setFilterData({ ...filterData, customer: e.target.value })} />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 8 }}>
                        <Col span={6}>Personel</Col>
                        <Col span={18}>
                            <Select showSearch allowClear style={{ width: '100%' }} placeholder="Lütfen Seçiniz" 
                            filterOption={true} optionFilterProp="label" 
                            onChange={(value) => setFilterData({ ...filterData, worker: value })}>
                                {
                                    tasksData?.workers?.map(worker => (
                                        <Option value={worker.id} key={worker.id} label={worker.name}>{worker.name}</Option>
                                    ))
                                }
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 8 }}>
                        <Col span={6}>İş Durumu</Col>
                        <Col span={18}>
                            <Select showSearch allowClear style={{ width: '100%' }} defaultValue={filterData?.status} placeholder="Lütfen Seçiniz" onChange={(value) => setFilterData({ ...filterData, status: value })}>
                                {
                                    !tasksData?.isLoading && tasksData?.status?.map(status => (
                                        <Option value={status.id} key={status.id}>{status.name}</Option>
                                    ))
                                }
                            </Select>
                        </Col>
                    </Row>

                    {
                        !tasksData?.isLoading && tasksData?.custom_fields?.map((field, key) => (

                            <Row key={key} style={{ marginBottom: 8 }}>
                                <Col span={6}>{field.field_name}</Col>
                                <Col span={18}>

                                    {field.field_type === 'combo' ?
                                        <Select mode="multiple" showSearch allowClear style={{ width: '100%' }}  placeholder="Lütfen Seçiniz" onChange={(value) => {

                                            setFilterData({ ...filterData, fields: { ...filterData.fields, [field.field_id]: value } })
                                        }
                                        }>
                                            {strToArr(field.field_value).map((s, key) => (<Option value={s} key={key}>{s}</Option>))}
                                        </Select>
                                        : field.field_type === 'number' ?
                                        (<NumericFormat
                                            customInput={Input}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            addonAfter="₺"
                                            placeholder={field.field_name}
                                            value={filterData?.field?.field_id}
                                            onChange={(e) => setFilterData({ ...filterData, fields: { ...filterData.fields, [field.field_id]: e.target.value } })}
                                        />) : field.field_type === 'phone' ?
                                        (<PatternFormat 
                                            customInput={Input} format="(###) ### ## ##"
                                            value={filterData?.field?.field_id}
                                            onChange={(e) => setFilterData({ ...filterData, fields: { ...filterData.fields, [field.field_id]: e.target.value } })}
                                        />) : field.field_type === 'bool' &&
                                        (<Switch  />)  
                                    }
                                </Col>
                            </Row>

                        ))
                    }

                    {(activePage === 'list') &&
                        <>
                        <Row style={{ marginBottom: 8 }}>
                        <Col span={6}>Gruplama Alanı</Col>
                        <Col span={18}>
                            <Select showSearch allowClear style={{ width: '100%' }}  placeholder="Lütfen Seçiniz" onChange={(value) => {

                                            setFilterData({ ...filterData, group : value })
                                        }
                                        }>
                                        <option value={1}>Müşteri Ad Soyad</option>
                                        <option value={2}>Çalışan</option>
                                        <option value={5}>Durum</option>
                                        {
                                        tasksData?.custom_fields?.map((field, key) => (
                                            <option value={key+6}>{field.field_name}</option>
                                        ))
                                        }
                            </Select>
                        </Col>
                        </Row>
                        <Row style={{ marginBottom: 8 }}>
                        <Col span={6}>Toplam Alanı</Col>
                        <Col span={18}>
                            <Select showSearch allowClear style={{ width: '100%' }}  placeholder="Lütfen Seçiniz" onChange={(value) => {

                                            setFilterData({ ...filterData, totalarea : value })
                                        }
                                }>

                                        {
                                        tasksData?.custom_fields?.map((field, key) => (
                                            <option value={key+6}>{field.field_name}</option>
                                        ))
                                        }
                            </Select>
                        </Col>
                        </Row>
                        </>
                    }
                </Modal>
                {/* Yeni İş Ekleme */}
                <Drawer
                    title={ selectedJobId> 0 ? "Düzenle" : "Yeni Ekle" }
                    placement="right"
                    size="large"
                    onClose={() => setAddNewModal(false)}
                    open={addNewModal}
                    extra={
                    <Space>
                        <Button onClick={()=>setAddNewModal(false)}>Kapat</Button>
                        <Button type="primary" loading={ saveJob.isLoading } onClick={()=> {newJobForm.submit()}}>
                        Kaydet
                        </Button>
                    </Space>
                    }
                >
                    <Spin tip="Yükleniyor..." spinning={taskDetailLoading}>
                    <Form scrollToFirstError form={newJobForm} onFinish={(values)=> {
                        console.log(values)
                        const imagesResponse = values?.images?.map(image => image.hasOwnProperty('response') ? image.response : image.id);
                        values.images = imagesResponse;

                        saveJob.mutate(values) 
                    }}  >
                        <Form.Item name="operation_id" style={{ display: 'none' }} initialValue="0">
                            <Input type="text" />
                        </Form.Item>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Row style={{ marginBottom: 4 }}>
                                    <Col span={24} style={{ padding : 5 }}>
                                        <Form.Item name="description" rules={[{ required: true, message: 'Lütfen açıklama giriniz!' }]}  >
                                            <TextArea rows={3}
                                                showCount maxLength={500}
                                                placeholder="İş Açıklaması"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 4 }}>
                                    <Col span={12} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'id']} rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                            <Select
                                                showSearch
                                                allowClear
                                                labelInValue
                                                placeholder="Müşteri Adı"
                                                defaultActiveFirstOption={true}
                                                showArrow={true}
                                                filterOption={false} 
                                                optionFilterProp="label"
                                                onSearch={ (newValue) => {
                                                    setSearchTerm(newValue)
                                                    if (newValue.length > 3) {
                                                        customer.mutate({ q: newValue})
                                                    } else {
                                                        setCustomerList([]);
                                                    }
                                                } }
                                                options={(customerList || []).map((d) => ({
                                                    value: d.id,
                                                    label: d.firstname+' '+d.lastname+' - '+d.phone_number,
                                                }))}
                                                onChange={(selectedValue) => {
                                                    console.log(selectedValue)
                                                    let customerInfo = customerList.filter((customer) => customer.id === selectedValue.value);
                                                    
                                                    newJobForm.setFieldsValue({
                                                        customer    : customerInfo[0]
                                                    })
                                                    
                                                }}
                                                dropdownRender={(menu) => (
                                                      <div>
                                                        {menu}
                                                        {!customer.isLoading &&
                                                        customerList?.length > 0 &&
                                                        !!searchTerm ? (
                                                        <div className="tail-px-3 tail-py-1 st-main-font">
                                                            <Divider plain style={{ margin: "5px 0px" }}>
                                                            Veya
                                                            </Divider>
                                                            <Button
                                                            type="primary"
                                                            size="small"
                                                            className="tail-mr-2"
                                                            onClick={addCustomer}
                                                            icon={<PlusOutlined />}
                                                            >
                                                            Yeni Müşteri Ekle
                                                            </Button>
                                                            <span className="s-semibold"> {searchTerm}</span>
                                                        </div>
                                                        ) : null}
                                                    </div>
                                                )}
                                                notFoundContent={
                                                    !customer.isLoading &&
                                                    customerList?.length === 0 &&
                                                    !!searchTerm ? (
                                                      <CustomerSelectNotFoundContent
                                                        customer={searchTerm}
                                                        addCustomer={addCustomer}
                                                      />
                                                    ) : (
                                                      <Empty
                                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        description={
                                                          customer.isLoading ? "Aranıyor.." : "Bulunamadı"
                                                        }
                                                      />
                                                    )
                                                  }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'phone_number']} rules={[{ required: true, message: 'Müşteri telefon numarası giriniz.' }]}  >
                                            <PatternFormat placeholder="Telefon" allowClear format="(###) ### ## ##" customInput={Input} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 4 }}>
                                    <Col span={24} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'address']} rules={[{ required: true, message: 'Müşteri adresi giriniz.' }]} >
                                            <Input placeholder="Müşteri Adresi" allowClear  />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 4 }}>
                                    <Col span={6} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'city']} rules={[{ required: true, message: 'İl seçiniz!' }]} >
                                            <Input placeholder="İl" allowClear />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'district']} rules={[{ required: true, message: 'İlçe seçiniz!' }]} >
                                            <Input placeholder="İlçe" allowClear />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'building_number']} rules={[{ required: true, message: 'Bina no giriniz.' }]} >
                                            <Input placeholder="Bina No" allowClear />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'apartment_number']} rules={[{ required: true, message: 'Daire no giriniz' }]} >
                                            <Input placeholder="Daire No" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ marginBottom: 4 }}>
                                    <Col span={24} style={{ padding : 5 }}>
                                        <Form.Item name={['customer', 'directions']} rules={[{ required: true, message: 'Çalışanın adresi daha rahat bulması için adres tarifi girebilirsiniz.' }]} >
                                            <Input placeholder="Adres Tarifi" allowClear />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{ marginBottom: 4 }}>
                                    <Col span={24} style={{ padding : 5 }}>
                                        <Form.Item name="worker_id" rules={[{ required: true, message: 'Görevi atamak istediğiniz kullanıcıyı seçiniz.' }]} >
                                            <Select showSearch style={{ width: '100%' }} allowClear placeholder="Sorumlu" filterOption={true} optionFilterProp="label">
                                                <Option value="">Seçiniz</Option>
                                                {tasksData?.workers?.map((person, key) => (
                                                    <Option value={person.id} key={person.id} label={person.name}>{person.name}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>


                                <Row style={{ marginBottom: 8 }}>
                                {tasksData?.custom_fields?.map((field, key) => (
                                        <Col span={12} style={{ padding : 5 }}>
                                            <Form.Item name={['custom_fields', field.field_id.toString()]} key={key} rules={[{ required: true, message: 'Bu alan zorunludur.' }]} >
                                                {field.field_type === 'combo' ?
                                                    (<Select showSearch 
                                                        allowClear 
                                                        style={{ width: '100%' }} 
                                                        placeholder={field.field_name}
                                                        mode="multiple"
                                                    >
                                                        {strToArr(field.field_value)?.map((s, key) => (<Option value={s} key={key}>{s}</Option>))}
                                                    </Select>) : field.field_type === 'number' ?
                                                (<NumericFormat
                                                    customInput={Input}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    decimalScale={2}
                                                    addonAfter="₺"
                                                    placeholder={field.field_name}
                                                />) : field.field_type === 'phone' ?
                                                (<PatternFormat customInput={Input} format="(###) ### ## ##" />)
                                                : field.field_type === 'bool' &&
                                                (<Switch  />)                                            
                                                }
                                            </Form.Item>
                                        </Col>
                                ))
                                }
                                </Row>

                                <Row style={{ marginBottom: 4 }}>
                                    <Col span={24} style={{ padding : 5 }}>
                                        <Form.Item name="start_time" valuePropName={'date'} rules={[{ required: true, message: 'Müşteri randevu tarihini giriniz!' }]} >
                                            <DatePicker 
                                                showTime 
                                                format="DD-MM-YYYY HH:mm"
                                                disabledDate={(current) => {return current <= dayjs().endOf('day') } } 
                                                getValueProps={(i) => ({value: i })}
                                                placeholder="Randevu Zamanı"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                <Form.Item
                                    name="images"
                                    valuePropName="fileList"
                                    getValueFromEvent={(e)=> {
                                        //console.log("resim data",e)
                                        
                                        if (Array.isArray(e)) {
                                            return e;
                                        }
                                        return e && e.fileList;
                                        
                                        /*
                                        if (Array.isArray(e)) {
                                            return e.map((file) => file.response);
                                          }
                                          return e && e.fileList && e.fileList.map((file) => file.response);
                                        */
                                    }}
                                    
                                >
                                    <Upload
                                        action={`${API_BASE_URL}/tasks/uploadImage`}
                                        listType="picture-card"
                                        headers={{ authorization: `Bearer ${localStorage.getItem("jwt_token")}`}}
                                        name="images"
                                        accept="image/*"
                                        onChange={(info) => {
                                            const { status } = info.file;
                                            if (status !== 'uploading') {
                                              console.log(info.file, info.fileList);
                                            }
                                            if (status === 'done') {
                                              message.success(`${info.file.name} file uploaded successfully.`);
                                            } else if (status === 'error') {
                                              message.error(`${info.file.name} file upload failed.`);
                                            }
                                        }}
                                    >
                                        <div>
                                            <PlusOutlined />
                                            <div style={{ marginTop: 8 }}>Yükle</div>
                                        </div>
                                    </Upload>
                                </Form.Item>
                                </Row>

                            </Col>
                        </Row>
                    </Form>
                    </Spin>

                </Drawer>
                {/* İş Detayları */}
                <Modal
                    title={taskDetailLoading ? "" : "İş Detayları - " + taskData?.operation?.operation_id }
                    centered
                    destroyOnClose={true}
                    open={jobDetailModal}
                    cancelText="Kapat"
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okButtonProps={{ style: { display: 'none' } }}
                    onCancel={() => { setjobDetailModal(false); setSelectedJobId(0) } }
                    width={'85%'}
                    bodyStyle={{height: '80vh', maxHeight: 'calc(100vh - 200px)'}}
                >
                    <Spin tip="Yükleniyor..." spinning={taskDetailLoading || taskisRefetching }>
                        <Tabs defaultActiveKey="1" tabPosition="left" >
                            <TabPane tab={<><InfoCircleOutlined /> İş Bilgileri</>} key="1" >
                                <Row>
                                    <Col span={18} style={{ paddingRight: 8, paddingLeft: 8 }}>
                                        <Row>
                                        <Col span={12}>
                                            <Card title="İş Bilgileri" bordered={true} bodyStyle={{ minHeight: 250 }} style={{boxShadow: '0 10px 40px 0 rgba(18,106,211,.07), 0 2px 9px 0 rgba(18,106,211,.06)', border: '1px solid #f2f2f2', transition: 'all .3s ease', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
                                                <p><strong>Personel : </strong> <span>{taskData?.operation?.worker?.name}</span></p>
                                                <p><strong>İş Durumu : </strong><span style={{ color: taskData?.operation?.status?.color }} >{taskData?.operation?.status?.name}</span></p>
                                                <p><strong>Oluşturma Tarihi : </strong> <span>{formatDate(taskData?.operation?.created_at)}</span></p>
                                                <p><strong>Başlama Tarihi : </strong> <span >{formatDate(taskData?.operation?.start_time)}</span></p>
                                                <p><strong>Bitirme Tarihi : </strong> <span >{taskData?.operation?.end_time && formatDate(taskData?.operation?.end_time)}</span></p>
                                            </Card>
                                        </Col>
                                        <Col span={12}>
                                            <Card title="Müşteri Bilgileri" bordered={true} bodyStyle={{ minHeight: 250 }} style={{boxShadow: '0 10px 40px 0 rgba(18,106,211,.07), 0 2px 9px 0 rgba(18,106,211,.06)', border: '1px solid #f2f2f2', transition: 'all .3s ease', backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
                                                <p><strong>Müşteri Adı : </strong><span>{taskData?.operation?.customer?.customer_name}</span></p>
                                                <p><strong>Telefon : </strong> <span>{taskData?.operation?.customer?.phone_number}</span></p>
                                                <p><strong>Adres : </strong> <span>{taskData?.operation?.customer?.address}</span></p>
                                            </Card>
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <div style={{ minHeight: 200,boxShadow: '0 10px 40px 0 rgba(18,106,211,.07), 0 2px 9px 0 rgba(18,106,211,.06)', border: '1px solid #f2f2f2', marginBottom: '1.875rem', transition: 'all .3s ease', backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '10px' }}>
                                                    <p>
                                                        <strong>Detaylar : </strong>
                                                        <span>{taskData?.operation?.description}</span>
                                                    </p>
                                                    {taskData?.operation?.fields?.map((field) => (
                                                        <p>
                                                            <strong> {field.field_detail?.field_name} :</strong>
                                                            <span>{field.value}</span>
                                                        </p>
                                                    ))
                                                    }

                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div style={{ minHeight: 200,boxShadow: '0 10px 40px 0 rgba(18,106,211,.07), 0 2px 9px 0 rgba(18,106,211,.06)', border: '1px solid #f2f2f2', marginBottom: '1.875rem', transition: 'all .3s ease', backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '10px' }}>
                                                    <p>
                                                        <strong>Müşteri İmzası : </strong>
                                                        <p> <Image
                                                            width={180}
                                                            src={taskData?.operation?.signature}
                                                        />
                                                        </p>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={6} style={{  paddingLeft: 8 }}>

                                        <Button type="primary" onClick={() => setAssignWorkerModal(true)} block>Yeniden Ata</Button>
                                        <Button type="primary" onClick={() => setChangeJobStatusModal(true)} block style={{ marginTop: 10 }}>İş durumunu değiştir</Button>
                                        <Button type="primary" onClick={() => copyJob()} block style={{ marginTop: 10 }}>İşi kopyala</Button>
                                        <Button type="primary" onClick={() => updateJob()} block style={{ marginTop: 10 }}>İşi düzenle</Button>
                                        <Popconfirm placement="right" title="Silmek istediginizden emin misiniz?" onConfirm={() => deleteJob.mutate({ operation_id : taskData.operation.operation_id  }) } okText="Evet" cancelText="Hayır">
                                            <Button type="primary" block danger style={{ marginTop: 10 }}>İşi Sil</Button>
                                        </Popconfirm>

                                    </Col>
                                </Row>

                            </TabPane>
                            <TabPane tab={<><CarryOutOutlined /> İş Etkinlikleri</>} key="2" style={{ padding: 20,  overflowY: 'auto',overflowX: 'hidden', maxHeight: 'calc(100vh - 200px)' }} >

                                <Timeline mode="alternate" style={{ paddingLeft: 10 }}>
                                    {taskData?.operation?.logs?.map((log) => {
                                        switch (log.status_id) {
                                            case 'create':
                                                return <Timeline.Item label={formatDate(log.created_at)} color="green"><span>{log.user?.name}</span> tarafından iş oluşturuldu.</Timeline.Item>
                                            case 'edit':
                                                return <Timeline.Item label={formatDate(log.created_at)} color="blue"><span>{log.user?.name}</span> tarafından düzenlendi.</Timeline.Item>
                                            case 'change_date':
                                                return <Timeline.Item label={formatDate(log.created_at)} color="yellow"><span>{log.user?.name}</span> tarafından <strong>iş tarihi değiştirildi.</strong></Timeline.Item>
                                            case 'change_worker':
                                                return <Timeline.Item label={formatDate(log.created_at)} color="pink"><span>{log.user?.name}</span> tarafından <strong>iş sorumlusu </strong> {log.new_value} değiştirildi.</Timeline.Item>
                                            case 'change_order_fields':
                                                return <Timeline.Item label={formatDate(log.created_at)} color="red"><span>{log.user?.name}</span> tarafından <strong>iş özel alanları değiştirildi.</strong></Timeline.Item>
                                            default:
                                                return <Timeline.Item label={formatDate(log.created_at)} color="gray"><span>{log.user?.name}</span> tarafından iş durumu <Tag color={log.status?.color}>{log.status?.name}</Tag> <strong> olarak değiştirildi.</strong></Timeline.Item>
                                        }
                                    })
                                    }
                                </Timeline>
                            </TabPane>
                            <TabPane style={{ }} tab={<><MessageOutlined /> İş Notları <Badge style={{ color: '#fff', backgroundColor: '#736cc7' }} count={taskData?.operation?.notes?.length} /> </>} key="3" >
                                <List
                                    style={{ paddingLeft: 20, overflowY: 'auto', maxHeight:  'calc(80vh - 300px)'  }}
                                    dataSource={taskData?.operation?.notes}
                                    itemLayout="horizontal"
                                    renderItem={props => {
                                            return(<Comment
                                            author={props.user.name}
                                            avatar={
                                                <Avatar src={props.user.profile_photo ? BASE_URL+'/userphoto/' + props.user.profile_photo : 'assets/img/avatars/nobody.jpg'}
                                                size={32} style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} alt={props.user.name}>{props.user.name.charAt(0)}</Avatar>
                                            }
                                            content={props.note}
                                            datetime={
                                                <Tooltip title={formatDate(props.created_at)}>
                                                    <span>{moment(props.created_at).fromNow()}</span>
                                                </Tooltip>
                                            }
                                            key={props.id}
                                            />)
                                    }}
                                />

                                <Comment
                                  avatar={
                                    <Avatar src={userData.profile_photo ? BASE_URL+'/userphoto/' + userData.profile_photo : 'assets/img/avatars/nobody.jpg'}
                                    size={32} style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }} 
                                    alt={userData.name}>{userData.name.charAt(0)}</Avatar>
                                  }
                                  content={<>
                                      <Form.Item>
                                        <TextArea rows={4} onChange={ (e)=>setComment(e.target.value) } value={comment} />
                                      </Form.Item>
                                      <Form.Item>
                                        <Button htmlType="submit"  type="primary" loading={addNote.isLoading} onClick={() =>  {
                                          if(!comment) return;
                                            addNote.mutate({ note : comment, jobId: selectedJobId })
                                        } }
                                        >
                                          Gönder
                                        </Button>
                                      </Form.Item></>} />

                            </TabPane>
                            <TabPane tab={<><StockOutlined /> Ürünler/Hizmetler</>} key="4" >
                                <Scrollbars style={{ height: 'calc(70vh)' }}>
                                    <TaskStock taskParts={taskData?.operation?.parts} taskId={selectedJobId} refetch={() => refetchTaskDetail() } />
                                </Scrollbars>
                            </TabPane>

                            <TabPane tab={<><FileImageOutlined /> Fotoğraflar <Badge style={{ color: '#fff', backgroundColor: '#736cc7' }} count={taskData?.operation?.images?.length} /></>} style={{ padding: 20,  overflowY: 'auto',overflowX: 'hidden', maxHeight: 'calc(100vh - 200px)' }}  key="5" >
                                    <Row>
                                        {taskData?.operation?.images.length > 0 && (
                                            <Image.PreviewGroup>
                                                {taskData?.operation?.images?.map((image, key) => (
                                                    <Col span={4} key={'image-'+key} style={{ boxShadow: '0 10px 40px 0 rgba(18,106,211,.07), 0 2px 9px 0 rgba(18,106,211,.06)', border: '1px solid #f2f2f2', marginBottom: '1.875rem', transition: 'all .3s ease', backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '10px' }}>
                                                        <Image width={150} src={BASE_URL + 'jobimages/' + image.image_file} />
                                                        <p><strong> {formatDate(image?.created_at)}</strong></p>
                                                        <p><strong> {image?.user?.name}</strong></p>
                                                    </Col>
                                                ))
                                                }
                                            </Image.PreviewGroup>
                                        ) }
                                    </Row>
                            </TabPane>
                        </Tabs>
                    </Spin>
                </Modal>
                {/* Özel Alanlar */}
                {(activePage === 'customFields') &&
                <Row>
                        <Col span={24}>
                            <Card title="İş Özel Alanları" bordered={false}
                                extra={<ul className="tail-list-none tail-p-0 tail-mt-1 tail-z-50">
                                    <div className="tail-float-left tail-ml-2">
                                        <Button onClick={() => { setcustomFieldModal(true); newFieldForm.resetFields() } } icon={<FolderAddOutlined />}>Yeni Özel Alan Ekle </Button>
                                    </div>
                                    <div className="tail-float-left tail-ml-2">
                                        <Button onClick={() => setActivePage('list')} icon={<OrderedListOutlined />}>Liste</Button>
                                    </div>
                                    <div className="tail-float-left tail-ml-2">
                                        <Button onClick={() => setActivePage('default')} >Harita</Button>
                                    </div>
                                </ul>}
                                bodyStyle={{ padding: 10 }} headStyle={{ background: '#736cc7', color: '#fff' }}>
                                <Table dataSource={tasksData?.custom_fields} rowKey={record => record.field_id} onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { newFieldForm.setFieldsValue(record); openCustomFieldForm(record) },
                                    };
                                }} >
                                    <Column title="Alan Adı" dataIndex="field_name" sorter="true" />
                                    <Column title="Alan Değeri" dataIndex="field_value" />
                                    <Column title="Alan Tipi" dataIndex="field_type" render={(type) => {
                                        switch (type) {
                                            case 'text':
                                                return 'Yazı';
                                            case 'number':
                                                return 'Numara';
                                            case 'date':
                                                return 'Tarih';
                                            case 'combo':
                                                return 'Çok seçenekli';
                                            case 'bool':
                                                return 'Evet / Hayır';
                                            case 'phone':
                                                return 'Telefon';
                                            default:
                                                return '';
                                        }
                                    }} />
                                </Table>
                            </Card>
                        </Col>
                </Row>
                }
                {/* Özel Alan Ekle & Düzenle */}
                <Modal
                    title="Özel Alan Ekle"
                    centered
                    open={customFieldModal}
                    onOk={() => { newFieldForm.submit() }}
                    onCancel={() => setcustomFieldModal(false)}
                    okText="Kaydet"
                    cancelText="İptal"
                    width={'25%'}
                    okButtonProps={ { loading: saveField.isLoading } }
                >
                    <Form scrollToFirstError form={newFieldForm} onFinish={(values)=> saveField.mutate(values) }  >
                        <Form.Item name="field_id" style={{ display: 'none' }} initialValue="0">
                            <Input type="text" />
                        </Form.Item>

                        <Row style={{ marginBottom: 8 }}>
                            <Col span={6}>Alan Adı</Col>
                            <Col span={18}>
                                <Form.Item name="field_name" rules={[{ required: true, message: 'Alan adını giriniz' }]}  >
                                    <Input type="text" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: 8 }}>
                            <Col span={6}>Alan Türü</Col>
                            <Col span={18}>
                                <Form.Item name="field_type" rules={[{ required: true, message: 'Alan tipini seçiniz' }]}  >
                                    <Select   allowClear style={{ width: '100%' }} placeholder="Lütfen Seçiniz">
                                        <Option value="text">Yazı</Option>
                                        <Option value="number">Numara</Option>
                                        <Option value="date">Tarih</Option>
                                        <Option value="combo">Çok seçenekli</Option>
                                        <Option value="bool">Evet / Hayır</Option>
                                        <Option value="phone">Telefon</Option>
                                        <Option value="mail">E-Posta</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.field_type !== currentValues.field_type}
                        >
                        {({ getFieldValue }) => getFieldValue('field_type') === 'combo' ? 
                        (<Row style={{ marginBottom: 8 }}>
                            <Col span={6}>Alan Değer</Col>
                            <Col span={18}>
                                <Form.Item name="field_value" rules={[{ required: true, message: 'Alan değeri giriniz' }]}  >
                                    <Input type="text" />
                                </Form.Item>
                            </Col>
                        </Row>) : ''
                        }
                        </Form.Item>
                    </Form>

                </Modal>
                {/* İş Adımları */}
                {(activePage === 'workSteps') &&
                <Row>
                        <Col span={24}>
                            <Card title="İş Adımları" bordered={false}
                                extra={<ul className="list-none p-0 mt-1 z-50">
                                    <div className="float-left ml-2">
                                        <Button onClick={() => setWorkstepModal(true)} icon={<FolderAddOutlined />}>Yeni Adım Ekle </Button>
                                    </div>
                                    <div className="float-left ml-2">
                                        <Button onClick={() => setActivePage('list')} icon={<OrderedListOutlined />}>Liste</Button>
                                    </div>
                                    <div className="float-left ml-2">
                                        <Button onClick={() => setActivePage('default')} >Harita</Button>
                                    </div>
                                </ul>}
                                bodyStyle={{ padding: 10 }} headStyle={{ background: '#736cc7', color: '#fff' }}>
                                <Row>
                                    <Col span={12} style={{ padding : 20 }}>

                                    <Divider orientation="left">Saha İş Adımları</Divider>
                                    <ReactDragListView
                                        nodeSelector=".ant-list-item.draggable-item"
                                        lineClassName="dragLine"
                                        onDragEnd={(fromIndex, toIndex) =>
                                            onDragEnd(fromIndex, toIndex, "operation")
                                        }
                                    >
                                        <List
                                        dataSource={ tasksData?.status?.filter(status => status.value === null) }
                                        renderItem={(item) => (
                                            <List.Item key={item.id} className="draggable-item">
                                                <DragOutlined className="icon" />
                                                <p style={{ color: item.color }}  className="grabbable" >{item.name}</p>
                                                <List.Item.Meta  className="grabbable"/>
                                            </List.Item>
                                        )}
                                        />
                                    </ReactDragListView>

                                    <Divider orientation="left">Zorunlu İş Adımları</Divider>

                                    <List
                                        dataSource={ tasksData?.status?.filter(status => status.value != null) }
                                        renderItem={(item) => (
                                            <List.Item key={item.id}>
                                                <p style={{ color: item.color }} >{item.name}</p>
                                                <List.Item.Meta />
                                            </List.Item>
                                        )}
                                    />

                                    </Col>
                                    <Col span={12}  style={{ padding : 20 }}>
                                        <h4 style={{ fontWeight: 600, color: '#1d364b' }}>İŞ ADIMLARI</h4>
                                        <p style={{ color: '#5f6c74', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
                                            İş sürecinizi yönetmenizde ve raporlanabilir veriler almanızda sizlere yardımcı olur. Personelinizin kullandığı uygulama içerisinde iş süreçlerini takip etmesini sağlayan aşamalar oluşturur. Bu adımları kendiniz belirleyebilir ihtiyaca göre ekleme ve çıkarma yapabilirsiniz.
                                    </p>
                                        <p style={{ color: '#5f6c74', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>
                                            Örneğin; ''Sipariş alındı'' ''Teslimat yola çıktı'' şeklinde faaliyet alanınıza uygun sekmeler oluşturabilirsiniz.
                                    </p>
                                        <h4 className="fs16 mt15 mb5" style={{ fontWeight: 600, color: '#1d364b' }}>SMS</h4>
                                        <p style={{ color: '#5f6c74', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>Personellerinize tanımladığınız iş adımlarında müşterilerinize ulaştırmak istediğiniz herhangi bir not var ise bunu aşama geçişlerinde otomatik olarak yapabilirsiniz.</p>
                                        <p style={{ color: '#5f6c74', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>Örneğin; ''Teslimat yola çıktı'' iş adımında müşterinize ''Teslimatınız hazır ve yola çıkmıştır. İlgili linkten canlı olarak takip edebilirsiniz'' şeklinde bir sms taslağı oluşturabilir ve müşterilerinize takip imkanı sunabilirsiniz.</p>
                                        <h4 className="fs16 mt15 mb5" style={{ fontWeight: 600, color: '#1d364b' }}>ZORUNLU İŞ ADIMLARI</h4>
                                        <p style={{ color: '#5f6c74', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>Zorunlu iş adımları sistemde değiştirilemez ve olması gerekli adımlardır. Bunlar; İş süreçlerinin herhangi bir sebepten ötürü iptal olması durumunda kullanılacak ''iptal'' sekmesi, iş sürecinin herhangi bir şekilde tamamlanması durumundaki ''tamamlandı'' sekmesidir.</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                </Row>
                }
                {/* İş Adımları Ekle & Düzenle */}
                <Modal
                    title="Özel Alan Ekle"
                    centered
                    open={workstepModal}
                    onOk={() => setWorkstepModal(false)}
                    onCancel={() => setWorkstepModal(false)}
                    width={'40%'}
                >

                    <div className="card-body">
                        <input type="hidden" ng-model="workStep.id" />
                        <div className="form-group row">
                            <label htmlFor="inputPassword3" className="col-sm-3 col-form-label">Alan Adı</label>
                            <div className="col-sm-9">
                                <input className="form-control" type="text" ng-model="workStep.name" placeholder="Alan Adı" ng-disabled="workStep.value == 'created'" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputPassword3" className="col-sm-3 col-form-label">Renk</label>
                            <div className="col-sm-9">
                                <ul className="color-palette">
                                    <li ng-repeat="color in colorList" ng-style="{'background-color': color }" ng-click="workStep.color = color" ng-class="workStep.color == color ? 'selected' : ''">
                                    </li>
                                    <CirclePicker color={selectedColor} onChangeComplete={(color, event) => setSelectedColor(color)} />
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* İş Durum Değiştir */}
                <Modal
                    title="İş Durumunu Değiştir"
                    centered
                    open={changeJobStatusModal}
                    onOk={() => { changeStatus.mutate({ operation_id: taskData.operation.operation_id , status_id : jobStatus }); } }
                    onCancel={() => setChangeJobStatusModal(false)}
                    okText="Değiştir"
                    cancelText="İptal"
                    width={'20%'}
                    okButtonProps={ { loading: changeStatus.isLoading } }
                >
                    <Select showSearch optionFilterProp="label" defaultValue={tasksData?.jobDetail?.status_id} onChange={(val)=> setJobStatus(val) }  style={{ width: '100%' }}>
                        {tasksData?.status?.map((status, key) => (
                            <Option value={status.id} key={key}>{status.name}</Option>
                        ))}
                    </Select>
                </Modal>
                {/* Yeniden Ata */}
                <Modal
                    title="Yeniden Ata"
                    centered
                    open={assignWorkerModal}
                    onOk={() => { changeResponsible.mutate({ operation_id: taskData.operation.operation_id , worker_id : responsibleUser }); } }
                    onCancel={() => setAssignWorkerModal(false)}
                    okText="Değiştir"
                    cancelText="İptal"
                    width={'20%'}
                    okButtonProps={ { loading: changeResponsible.isLoading } }
                >
                   
                    <Select showSearch optionFilterProp="label" defaultValue={tasksData?.jobDetail?.worker_id} onChange={(val)=> setResponsibleUser(val) } style={{ width: '100%' }}>
                        {tasksData?.workers?.map((person, key) => (
                            <Option value={person.id} key={key}>{person.name}</Option>
                        ))}
                    </Select>
                    
                </Modal>
                {/* Çalışan Detayları */}
                <Modal
                    title="Çalışan Detayları"
                    centered
                    open={workerDetailModal}
                    onOk={() => setWorkerDetailModal(false)}
                    onCancel={() => setWorkerDetailModal(false)}
                    width={'40%'}
                >
                    <div className="modal-body">
                        <form action="#" className="form-horizontal" id="workerEditForm">
                            <input type="hidden" name="id" id="workerId" defaultValue={0} />
                            <div className="form-body">
                                <div className="row">
                                    <ul className="nav nav-pills nav-pills-info mb-3" id="pills-demo-3" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link btn-floating active" id="pills-7-tab" data-toggle="pill" href="#v-pills-userinfo" role="tab" aria-controls="pills-7" aria-selected="true"><i className="la la-home" />Personel Bilgileri</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link btn-floating" id="pills-8-tab" data-toggle="pill" href="#v-pills-activitiy" role="tab" aria-controls="pills-8" aria-selected="false"><i className="la la-user" />Personel Etkinlikleri</a>
                                        </li>
                                    </ul>
                                    <div className="col-md-12 col-lg-9">
                                        <div className="tab-content" id="my-account-tabsContent">
                                            <div className="tab-pane fade active show" id="v-pills-userinfo" role="tabpanel" aria-labelledby="v-pills-userinfo-tab">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row mt15">
                                                            <div className="col-lg-12">
                                                                <div style={{ boxShadow: '0 10px 40px 0 rgba(18,106,211,.07), 0 2px 9px 0 rgba(18,106,211,.06)', border: '1px solid #f2f2f2', marginBottom: '1.875rem', transition: 'all .3s ease', backgroundColor: 'rgba(0, 0, 0, 0.03)', padding: '10px' }}>
                                                                    <p><strong>Ad Soyad : </strong> <span ng-bind="selectedWorkerData.name" /></p>
                                                                    <p><strong>Oluşturma Tarihi : </strong> <span ng-bind="formatDate(selectedWorkerData.created_at) | date : dateFormat" /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-activitiy" role="tabpanel" aria-labelledby="v-pills-activitiy-tab">
                                                {/* ngIf: log.proccess == 'rate' */}
                                                {/* ngIf: log.proccess == 'take_over' */}
                                                {/* ngIf: log.proccess == 'take_on' */}
                                                <div className="timeline timeline-border">
                                                    <div ng-repeat="log in selectedData.logs">
                                                        <div className="timeline-list" ng-if="log.status_id == 'create'">
                                                            <div className="timeline-info">
                                                                <div><a href="#!"><strong ng-bind="log.user.name" /></a> tarafından iş oluşturuldu. </div>
                                                                <small className="text-muted" ng-bind="log.created_at | date:dateFormat" />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-list timeline-border timeline-primary" ng-show="isNumber(log.status_id)">
                                                            <div className="timeline-info">
                                                                <div><a href="#!"><strong ng-bind="log.user.name" /></a> tarafından iş durumu "<strong ng-bind="log.status.name" ng-style="{ color : log.status.color}"> </strong>""  olarak değiştirildi.</div>
                                                                <small className="text-muted" ng-bind="log.created_at | date:dateFormat" />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-list timeline-border timeline-accent" ng-if="log.status_id == 'edit'">
                                                            <div className="timeline-info">
                                                                <div><a href="#!"><strong ng-bind="log.user.name" /></a> tarafından düzenlendi.</div>
                                                                <small className="text-muted" ng-bind="log.created_at | date:dateFormat" />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-list timeline-border timeline-success" ng-if="log.status_id == 'change_date'">
                                                            <div className="timeline-info">
                                                                <div><a href="#!"><strong ng-bind="log.user.name" /></a> tarafından <strong>iş tarihi değiştirildi.</strong></div>
                                                                <small className="text-muted" ng-bind="log.created_at | date:dateFormat" />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-list timeline-border timeline-warning" ng-if="log.status_id == 'change_worker'">
                                                            <div className="timeline-info">
                                                                <div><a href="#!"><strong ng-bind="log.user.name" /></a> tarafından <strong>iş sorumlusu değiştirildi.</strong></div>
                                                                <small className="text-muted" ng-bind="log.created_at | date:dateFormat" />
                                                            </div>
                                                        </div>
                                                        <div className="timeline-list timeline-border timeline-info" ng-if="log.status_id == 'change_order_fields'">
                                                            <div className="timeline-info">
                                                                <div><a href="#!"><strong ng-bind="log.user.name" /></a> tarafından <strong>iş özel alanları değiştirildi.</strong></div>
                                                                <small className="text-muted" ng-bind="log.created_at | date:dateFormat" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Kapat</button>
                    </div>
                </Modal>
        </>
    )

}


export default Tasks;