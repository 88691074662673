import {
    DashboardOutlined, BarChartOutlined, PlusCircleOutlined, MinusCircleOutlined, MoneyCollectOutlined, 
    BarcodeOutlined, SettingOutlined, CopyOutlined, TeamOutlined,FlagOutlined, InteractionOutlined,HddOutlined,EnvironmentOutlined
} from '@ant-design/icons';

export default {
    items: [
        {
            id: 'dashboard',
            title: 'Güncel Durum',
            type: 'item',
            url: '/',
            icon: <DashboardOutlined />
        },
        {
            id: 'tasks',
            title: 'Saha İşleri',
            type: 'item',
            url: '/tasks',
            icon: <BarChartOutlined />
        },
        {
            id: 'incoming',
            title: 'Gelirler',
            type: 'collapse',
            url: '/',
            icon: <PlusCircleOutlined />,
            children: [
                {
                    id: 'invoices',
                    title: 'Faturalar',
                    type: 'item',
                    icon: <CopyOutlined />,
                    url: '/invoices'
                },
                {
                    id: 'customers',
                    title: 'Müşteriler',
                    type: 'item',
                    icon: <TeamOutlined />,
                    url: '/customers'
                }
            ]
        },
        {
            id: 'outgoing',
            title: 'Giderler',
            type: 'collapse',
            url: '/',
            icon: <MinusCircleOutlined />,
            children: [
                {
                    id: 'cost',
                    title: 'Gider Belgeleri',
                    type: 'item',
                    url: '/expenses'
                },
                {
                    id: 'suppliers',
                    title: 'Tedarikçiler',
                    type: 'item',
                    url: '/suppliers'
                }
            ]
        },
        {
            id: 'cash',
            title: 'Nakit',
            type: 'collapse',
            url: '/',
            icon: <MoneyCollectOutlined />,
            children: [
                {
                    id: 'banksAndAccounts',
                    title: 'Kasalar ve Bankalar',
                    type: 'item',
                    url: '/accounts'
                }
            ]
        },
        {
            id: 'items',
            title: 'Stok & Envanter',
            type: 'collapse',
            url: '/',
            icon: <BarcodeOutlined />,
            children: [
                {
                    id: 'serviceAndItems',
                    title: 'Hizmet ve Ürünler',
                    type: 'item',
                    url: '/items',
                    icon: <BarcodeOutlined />,
                },
                {
                    id: 'warehouses',
                    title: 'Depolar',
                    type: 'item',
                    url: '/warehouses',
                    icon: <FlagOutlined />,
                },
                {
                    id: 'transferBetweenWH',
                    title: 'Depolar arası transfer',
                    type: 'item',
                    url: '/warehouseTransfer',
                    icon: <InteractionOutlined />,
                },
                {
                    id: 'stockActions',
                    title: 'Stok Hareketleri',
                    type: 'item',
                    url: '/items',
                    icon: <InteractionOutlined />,
                },
                {
                    id: 'inventory',
                    title: 'Envanter',
                    type: 'item',
                    url: '/inventory',
                    icon : <HddOutlined />
                }
            ]
        },        

        {
            id: 'settings',
            title: 'Ayarlar',
            type: 'collapse',
            url: '/',
            icon: <SettingOutlined />,
            children: [
                {
                    id: 'companyinfo',
                    title: 'Firma Bilgileri',
                    type: 'item',
                    icon : <EnvironmentOutlined />,
                    url: '/settings'
                },
                {
                    id: 'users',
                    title: 'Kullanıcılar',
                    type: 'item',
                    icon: <TeamOutlined />,
                    url: '/users'
                }
                
            ]
        }
    ]
}