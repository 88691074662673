import React, { useState, useLayoutEffect } from 'react'
import TemplateNew from '../../components/TemplateNew'
import { Table, Spin, Layout, Modal, Form,  Button, DatePicker, Input, InputNumber, Typography, Divider, Card, Row, Col } from 'antd';
import {FileAddOutlined} from '@ant-design/icons';

import moment from 'moment'
import AybimTable from '../../components/AybimTable'
import FilterDropdown from '../../components/FilterDropdown'


const Accounts = () => {

    const { Column } = Table;
    const { Content } = Layout;
    const { Title } = Typography;

    const [newCustomerForm] = Form.useForm();

    const [customerModal, setCustomerModal] = useState(false);
    const [customerData, setCustomerData] = useState([]);

    const [bankAccount, setBankAccount] = useState(0);
    const [cashAccount, setCashAccount] = useState(0);

    const dateFormat = 'DD-MM-YYYY';

    const saveCustomerData = () => {
        console.log(newCustomerForm.getFieldsValue());
    }

    const calculateTotals = (data) => {
        var totalBank = 0;
        var totalCash = 0;

        data?.data?.map(account => {
            if(account?.accountType == "bank")
                totalBank = parseFloat(totalBank) + parseFloat(account?.openBalance)
            
            if(account?.accountType == "cash")
                totalCash = parseFloat(totalCash) + parseFloat(account?.openBalance)

        })

        setBankAccount(totalBank.toFixed(2))
        setCashAccount(totalCash.toFixed(2))
    }

    return (
        <div>
            <Row gutter={[16, 16]} style={{ paddingBottom: 15 }}>
                                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                                    <Card
                                        bodyStyle={{
                                            paddingBottom: 30,
                                            paddingLeft: 20
                                        }}
                                        style={{
                                            
                                            backgroundColor: "#35c7bd",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "right",
                                            backgroundImage : "linear-gradient(115deg, #35c7bd, #4ee2d8)"
                                        }}
                                    >
                                        <div className="safe-card content-cards">
                                            <Title level={3} className="text">Bankalar Toplamı</Title>
                                            <Title level={3} className="text"> { bankAccount }</Title>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                                    <Card
                                        bodyStyle={{
                                            paddingBottom: 30,
                                            paddingLeft: 20
                                        }}
                                        style={{
                                            backgroundColor: "#51d8ff",
                                            backgroundRepeat: "no-repeat",
                                            backgroundImage : "linear-gradient(294deg, #51d8ff, #5cdec7)"
                                        }}
                                    >
                                        <div className="bank-card content-cards">
                                        <Title level={3} className="text">Kasalar Toplamı</Title>
                                        <Title level={3} className="text"> { cashAccount }</Title>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                                    <Card
                                        bodyStyle={{
                                            paddingBottom: 30,
                                            paddingLeft: 20
                                        }}
                                        style={{
                                            background: "url(images/new-orders.png)",
                                            backgroundColor: "#8ad2f8",
                                            backgroundRepeat: "no-repeat",
                                            backgroundPosition: "right",
                                            backgroundImage : "linear-gradient(115deg, #8ad2f8, #c1b8ff)"
                                        }}
                                    >
                                        <div className="status-card content-cards">
                                        <Title level={3} className="text">Nakit Toplamı</Title>
                                        <Title level={3} className="text"> { bankAccount }</Title>
                                        </div>
                                    </Card>
                                </Col>
                    </Row>
                <Card
                    title="Kasalar ve Bankalar"
                    extra={<Button type="primary" icon={<FileAddOutlined />} shape="round" onClick={() => { setCustomerModal(true); setCustomerData([]); }}>Yeni</Button>}
                >

                    
                    <AybimTable
                                url = "accounts/list"
                                tableName = "accountList"
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => { console.log(record); setCustomerModal(true); setCustomerData(record); },
                                    };
                                }}
                                returnData={(data)=> calculateTotals(data) }
                    >
                        <Column title="Sıra No" dataIndex="accountId" sorter="true" />
                        <Column title="Hesap No" dataIndex="accountName" sorter="true" />
                        <Column title="IBAN Numarası" dataIndex="iBan" key="iBan" sorter="true" responsive={['md']}/>
                        <Column title="Döviz Tipi" dataIndex="currency" render={(currency) => currency?.symbol} />
                        <Column title="Açılış Bakiyesi" dataIndex="openBalance" responsive={['md']} />
                        <Column title="Açılış Bakiyesi Tarihi" dataIndex="openDate" responsive={['md']}/>
                    </AybimTable>
                            
                </Card>

            {/* Müşteri Detayları */}
            <Modal
                title={"Kasa Detayları "}
                centered
                destroyOnClose={true}
                open={customerModal}
                cancelText="Kapat"
                okText="Kaydet"
                onOk={() => saveCustomerData()}
                onCancel={() => setCustomerModal(false)}
                width={'40%'}
            >
                <Spin tip="Yükleniyor..." spinning={customerData.isLoading}>
                    <Form form={newCustomerForm} onValuesChange={(values) => console.log(values)} >

                        <Row style={{ marginBottom: 0 }}>
                            <Col span={24}>
                                <Form.Item label={<label>Hesap Adı :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                    name="accountName" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                    <Input defaultValue={customerData.accountName} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: 0 }}>
                            <Col span={24}>
                                <Form.Item label={<label>Doviz :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                    name="firstname" rules={[{ required: true, message: 'Lütfen Ad Giriniz!' }]} >
                                    <Input defaultValue={customerData.currency?.code} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: 8 }}>
                            <Col span={12}>
                                <Form.Item label={<label>Açılış Bakiyesi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                    name="openBalance" >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        addonAfter="₺"
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        defaultValue={customerData.openBalance} step="0.01"
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label={<label>Açılış Bakiyesi Tarihi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                    name="openDate" rules={[{ required: true, message: 'Please input your username!' }]} >
                                    <DatePicker allowClear defaultValue={moment(customerData.openDate, dateFormat)} format={dateFormat} onChange={(date, dateString) => { console.log(date); }} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {(customerData.accountType === "bank") &&
                           
                            <Row style={{ marginBottom: 8 }}>
                                 <Divider dashed />
                                <Col span={12}>
                                    <Row style={{ marginBottom: 8 }}>
                                            <Col span={24}>
                                            <Form.Item label={<label>Banka Adı:</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                name="bankName" rules={[{ required: true, message: 'Please input your username!' }]} >
                                                <Input allowClear defaultValue={customerData.bankName} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 8 }}>
                                        <Col span={24}>
                                            <Form.Item label={<label>Hesap Numarası :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                name="bankAccountNumber" rules={[{ required: true, message: 'Please input your username!' }]} >
                                                <Input allowClear defaultValue={customerData.bankAccountNumber} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row style={{ marginBottom: 8 }}>
                                        <Col span={24}>
                                            <Form.Item label={<label>Banka Şubesi:</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                name="bankBranch" rules={[{ required: true, message: 'Please input your username!' }]} >
                                                <Input allowClear defaultValue={customerData.bankBranch} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginBottom: 8 }}>
                                        <Col span={24}>
                                            <Form.Item label={<label>IBAN Numarası :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                name="iBan" rules={[{ required: true, message: 'Please input your username!' }]} >
                                                <Input allowClear defaultValue={customerData.iBan} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Spin>
            </Modal >


        </div >
    )
}

export default Accounts