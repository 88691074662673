import React, { useState, useEffect, useLayoutEffect } from 'react'

import { Modal, Table, Form, Checkbox, Button, Select, DatePicker, Tag, Menu, Dropdown, Input, Spin, Tabs, Row, Col, Card, Image,Timeline, Avatar, Tooltip, Popconfirm, Badge,
    message, Upload, Typography, List, Drawer, Space, Divider, Switch, Empty,InputNumber
} from 'antd'

import {
    OrderedListOutlined, SettingFilled, EditOutlined, EllipsisOutlined, FolderOpenOutlined, FolderAddOutlined, FilterOutlined,CarryOutOutlined, MessageOutlined, PlusOutlined,
    InfoCircleOutlined, StockOutlined, DeleteOutlined,EnvironmentOutlined, CheckOutlined, CloseOutlined, SaveOutlined, DownOutlined
} from '@ant-design/icons'

import  { NumericFormat,PatternFormat }  from "react-number-format";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { currencyFormatter,getGrandTotalWithoutTax,getGrandTaxAmount,getGrandTotalWithTax,getAmountFromPercentageTotal,getTotalWithTax,getUnitPriceFromGrossTotal } from '../../../helpers';
import AybimSearch from '../../../components/AybimSearch';
import api from '../../../services/api';


const TaskStock = ({ taskParts,taskId, refetch }) => {

    const { Column } = Table;
    const { Option } = Select;

    const { t } = useTranslation();

    const [form] = Form.useForm();
    const isEditing = (record) => record.operation_part_id === editingKey;

    const [dataSource, setDataSource] = useState([])
    const [isDiscount, setIsDiscount] = useState(false)
    const [discountType, setDiscountType] = useState('%')
    const [discount, setDiscount] = useState(0)
    
    const [canDiscount, setCanDiscount] = useState(true)

    const [editingKey, setEditingKey] = useState("");

    useEffect(()=>{
        setDataSource(taskParts)
    },[taskParts])

    const [taxTotal,setTaxTotal]      = useState(0)
    const [grandTotal,setGrandTotal]  = useState(0)
    const [grandTotalWithoutTax,setGrandTotalWithoutTax] = useState(0)
    const [vats,setVats ]           = useState({})

    useEffect(()=>{
      setVats({})
      dataSource?.map((data,key)=>{
        setVats(state => ({
          ...state,
          [ data.tax_rate ]: getGrandTaxAmount(dataSource, data.tax_rate)
        }))
      })

      setTaxTotal(getGrandTaxAmount(dataSource))
      setGrandTotal(getGrandTotalWithTax(dataSource))
      setGrandTotalWithoutTax(getGrandTotalWithoutTax(dataSource))

    },[dataSource,taskParts])

    useEffect(()=>{

      /*
      Object.keys(vats).map((keyName, i) => (
        console.log("Kdv",keyName,"-",vats[keyName])
      ));
      */
      
    },[vats])

    const { isLoading: saveLoading, mutate : saveNewPart  } = useMutation(params => { return api.getQueryData({url: 'tasks/parts/save', params}) } ,  {
      onSuccess: async (data, variables, context) => {
        console.log(data)
        refetch()
          //setSearchResults(data)
      }       
    })

    const { isLoading: deleteLoading, mutate : deletePart  } = useMutation(params => { return api.getQueryData({url: 'tasks/parts/delete', params, method: 'delete'}) } ,  {
      onSuccess: async (data, variables, context) => {
        console.log(data)
        refetch()
          //setSearchResults(data)
      }       
    })

    const getNetTotal = () => {
        const discountValue = isDiscount ? discount : discount;
        const discountTypeValue = isDiscount  ? discountType : discountType;
        const discountAmount = discountTypeValue === "fixed" ? discountValue || 0 : getAmountFromPercentageTotal(discountValue, grandTotal);

        return grandTotal - discountAmount ;
    };

    const edit = (record) => {
        console.log("edit",record)
        form.setFieldsValue({
          operation_part_id: "",
          price: "",
          tax_rate: "",
          qty: "",
          total_price: "",
          part: "",
          operation_id:"",
          ...record,
        });
        setEditingKey(record.operation_part_id);
    };
    
    const cancel = () => {
        setEditingKey("");
    };
    
    const save = async (key) => {
        try {
          const row = await form.validateFields();
          const newData = [...dataSource];
          const index = newData.findIndex((item) => key === item.operation_part_id);

          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, { ...item, ...row });
            setDataSource(newData);
            setEditingKey("");
          } else {
            newData.push(row);
            setDataSource(newData);
            setEditingKey("");
          }

          console.log("save item",newData[index])

          saveNewPart({
            ...newData[index],
            operation_id : taskId
          })

        } catch (errInfo) {
          console.log("Validate Failed:", errInfo);
        }
    };
    
    const handleAdd = () => {
        const randomId = parseInt(Math.random() * 1000);
        
        const newData = {
          operation_part_id: randomId,
          newItem : true,
          tax_rate : 0
        };
        
        setDataSource((pre) => {
          return [...pre, newData];
        });
        setEditingKey(randomId);
        form.resetFields()
    };
    
    const handleDelete = (key) => {
        const checkStatus = dataSource.find((item) => item.operation_part_id == key);
        console.log("Delete",checkStatus)

        if(checkStatus.newItem){
          const newdataSource = dataSource.filter((item) => item.operation_part_id !== key);
          setDataSource(newdataSource);
          setEditingKey("");
        }
        else{
          deletePart({key})
        }
    };

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
      }) => {
        let inputNode;

        if (dataIndex === "part_id") {
          inputNode = (
            <AybimSearch
                  entity="items/search"
                  placeholder={ t("taskStock.selectStock") }
                  currentValue={record?.part?.id}
                  showSearch
                  allowClear
                  renderOptions={(items = []) =>
                    items.map((item) => (
                      <Select.Option key={item.id} value={item.id} item={item}>
                        {item.name} {item.barcode ? `- ${item.barcode}` : ""}
                      </Select.Option>
                    ))
                  }
                  onChange={(itemId, part) => {
                    const lineData = {
                        qty             : 1,
                        price           : part?.item?.sale_price,
                        tax             : part?.item?.tax,
                        tax_rate        : part?.item?.tax?.rate ?? 18
                    };


                    form.setFieldsValue({
                        ...lineData,
                        part : part?.item,
                        total_price: getTotalWithTax(lineData).toFixed(2),
                    });

                  }}
            />
          );
        } else if (dataIndex === "qty") {
            inputNode = (
                <InputNumber 
                min={1}        
                placeholder={t("stockList.qty")}
                onChange={(qty) => {

                    let currentVal = form.getFieldsValue()

                    const lineData = {
                        ...currentVal,
                        qty            
                    };

                    form.setFieldsValue({
                        ...lineData,
                        total_price: getTotalWithTax(lineData).toFixed(2),
                    });

                }}/>
            );
        } else if (dataIndex === "price") {
            inputNode = (
                <NumericFormat
                customInput={Input}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                disabled="true"
                />
            );
        } else if (dataIndex === "total_price") {
            inputNode = (
                <NumericFormat
                customInput={Input}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                onChange={(val)=>{
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    total_price : val
                }) }}
                />
            );
        } else if (dataIndex === "tax_rate") {
          inputNode = (
                    <AybimSearch
                    entity="tasks/taxes/search"
                    placeholder={ t("taskStock.selectTax") }
                    currentValue={form.getFieldValue("tax_rate")}
                    renderOptions={(items = []) =>
                      items.map((item) => {
                        return ( 
                        
                          <Select.Option key={item.id} value={item.rate} item={item}>
                              {item.rate} %
                          </Select.Option>
                      )})
                    }
                    onChange={(itemId, taxRate) => {
                        const lineData = {
                          ...form.getFieldsValue(),
                          tax             : taxRate?.item,
                          tax_rate        : taxRate?.item?.rate,
                        };

                        form.setFieldsValue({
                            ...lineData,
                            total_price: getTotalWithTax(lineData).toFixed(2),
                        });

                    }}
            />
          );
        } else {
          inputNode = <Input />;
        }

        return (
          <td {...restProps}>
            {editing ? (
            <>
              <Form.Item
                name={dataIndex}
                style={{
                  margin: 0,
                }}
                rules={[
                  {
                    required: true,
                    message: `${title} alanını giriniz!`,
                  },
                ]}
              >
                {inputNode}
              </Form.Item>
              {
                dataIndex === "part_id" && 
                <Form.Item
                    name="part"
                    noStyle
                    hidden
                >
                    <Input />
                </Form.Item>
              }
                            {
                dataIndex === "tax_rate" && 
                <Form.Item
                    name="tax"
                    noStyle
                    hidden
                >
                    <Input />
                </Form.Item>
              }
              </>
            ) : (
              children
            )}
          </td>
        );
    };

    const columns = [
        {
          title: t("stock.item"),
          dataIndex: "part_id",
          render: (text, record) => (
            <div
              className="tail-truncate"
              title={record?.part?.name}
              style={{ maxWidth: "250px" }}
            >
              {record?.part?.name}
            </div>
          ),
          editable: true,
        },
        {
          title: "Miktar",
          dataIndex: "qty",
          editable: true,
          isNumber : true,
          align: "right",
          width: "15%",
        },
        {
          title: "Birim Fiyat",
          dataIndex: "price",
          editable: true,
          isNumber : true,
          align: "right",
          width: "15%",
          render: (text) => currencyFormatter(parseFloat(text), false),
        },
        {
          title: "Kdv Oranı",
          dataIndex: "tax_rate",
          editable: true,
          align: "right",
          width: "15%",
          render: (text) => `${Number.isFinite(text) ? text : 18}%`,
        },
        {
          title: "Toplam",
          dataIndex: "total_price",
          editable: true,
          isNumber: true,
          align: "right",
          render: (text) => currencyFormatter(parseFloat(text), false),
        },
        {
          title: "İşlemler",
          dataIndex: "actions",
          render: (_, record) => {
            const editable = isEditing(record);
            return editable ? (
                <Space wrap>
                <Typography.Link
                  onClick={() => save(record.operation_part_id)}
                  style={{
                    marginRight: 8,
                  }}
                >
                  <Button type="primary" icon={<SaveOutlined />} />
                </Typography.Link>
                {
                record.newItem ?
                <Popconfirm
                  title="Malzeme silinsin mi?"
                  onConfirm={() => handleDelete(record.operation_part_id)}
                  okText="Evet"
                  cancelText="Hayır"
                >
                  <Button danger icon={<DeleteOutlined />} />
                </Popconfirm>
                :              
                <Popconfirm 
                    title="İptal edilsin mi?" 
                    onConfirm={cancel}
                    okText="Evet"
                    cancelText="Hayır"
                >
                  <a>Iptal</a>
                </Popconfirm>
                }
              </Space>
            ) : (
                <Space wrap>
                    <Typography.Link
                    disabled={editingKey !== ""}
                    onClick={() => edit(record)}
                    >
                    <Button type="primary" icon={<EditOutlined />} />
                    </Typography.Link>
                    <Space />
                    <Popconfirm
                    title="Malzeme silinsin mi?"
                    onConfirm={() => handleDelete(record.operation_part_id)}
                    okText="Evet"
                    cancelText="Hayır"
                    >
                    <Button danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            );
          },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
    
        return {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.isNumber ? "number" : "text",
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record)
          }),
        };
    });

    return(<>
      <Spin tip="Yükleniyor..." spinning={saveLoading || deleteLoading }>
        <Form form={form} component={false}>
            <Table
            dataSource={dataSource}
            columns={mergedColumns}
            pagination={false}
            rowClassName="editable-row"
            rowKey={record => record.operation_part_id}
            style={{ paddingLeft: 10 }}
            components={{
                body: {
                  cell: EditableCell,
                  //row: EditableRow,
                },
            }}
            locale={{
                emptyText: <Empty description={t("tables.noData")} />,
            }}
            />
        </Form>
      
        <div className="tail-flex tail-justify-stretch">
          <div className="tail-flex tail-flex-col tail-space-y-2 tail-pt-6 tail-justify-between tail-flex-grow">

                <div>
                <Button
                    className="tail-s-stock-add-btn tail-ml-4 tail-mr-2 tail-s-hover-target tail-self-start"
                    onClick={handleAdd}
                    disabled={editingKey}
                >
                    Malzeme & Hizmet Ekle
                </Button>
                </div>

                <div className="tail-mb-20 tail-self-end">

                {isDiscount ? (
                <div className="tail-mb-1">
                  <Button
                    type="text"
                    size="small"
                    onClick={() => {
                      setDiscount(0)
                      setIsDiscount(false)
                      setDiscountType('%')
                      }
                    }
                  >
                    <CloseOutlined className="tail-text-red-500" />
                  </Button>
                  <Divider type="vertical" />
                  <Button type="text" size="small" >
                    <CheckOutlined className="tail-text-blue-700" />
                  </Button>
                </div>
                ):(<Button
                    type="link"
                    onClick={() => {
                        setDiscount(0)
                        setIsDiscount(true)
                        setDiscountType('%')
                        }
                    }
                    >
                    {t("stock.addDiscount")}
                    </Button>
                )
                }
                </div>
          </div>
          <div className="tail-flex tail-flex-col tail-space-y-2 tail-pt-6 tail-w-2/5">
              <div className="tail-flex tail-w-full tail-justify-between tail-md:w-1/2">
                <span className="tail-font-bold">Ara Toplam:</span>
                <span>{currencyFormatter(grandTotalWithoutTax,true)}</span>
              </div>

              {
                    Object.keys(vats).map((keyName, i) => (
                      <div className="tail-flex tail-w-full tail-justify-between tail-md:w-1/2">
                        <span className="tail-font-bold">{  t('stockList.vat', { val: keyName }) }:</span>
                        <span>
                          {currencyFormatter(vats[keyName], true)}
                        </span>
                      </div>
                    ))
              }

              <div className="tail-flex tail-w-full tail-justify-between tail-md:w-1/2">
                <span className="tail-font-bold">Toplam Kdv:</span>
                <span>
                  {currencyFormatter(taxTotal, true)}
                </span>
              </div>
                   
              {isDiscount ? (
                <div className="tail-flex tail-w-full tail-justify-between tail-md:w-1/2">
                  <span className="tail-font-bold">İndirim:</span>
                  <span>
                    <Dropdown
                        overlay={
                        <Menu >
                            <Menu.Item key="%">{t("stock.percentage")}</Menu.Item>
                            <Menu.Item key="fixed">{t("stock.fixed")}</Menu.Item>
                        </Menu>
                        }
                    >
                        <Button
                        className="tail-inline-flex tail-items-center"
                        size="small"
                        >
                        {discountType === "%"
                            ? t("stock.percentage")
                            : t("stock.fixed")}
                        <DownOutlined />
                        </Button>
                    </Dropdown>
                    <InputNumber
                        size="small"
                        value={discount}
                        precision={2}
                        placeholder={t("stock.discount")}
                        formatter={(value) =>
                        discountType === "%" ? `${value}%` : `${value}`
                        }
                        min={0}
                        max={discountType === "%" ? 100 : grandTotal}
                        decimalSeparator=","
                        onChange={(discount = 0) => console.log("discount",discount) }
                    />
                  </span>
                </div>
                ) : null}
                {canDiscount && !isDiscount && discount > 0 && (
                    <p className="tail-pb-2">
                    {currencyFormatter(
                        grandTotal - getNetTotal(),
                        true
                    )}
                    </p>
                ) }

              <div className="tail-flex tail-w-full tail-justify-between tail-border-t tail-pt-2 tail-md:w-1/2">
                <span className="tail-font-bold">Genel Toplam:</span>
                <span className="tail-font-bold">
                {currencyFormatter(getNetTotal(), true)}
                </span>
              </div>
          </div>
        </div>
      </Spin>
    </>);

}

export default TaskStock;