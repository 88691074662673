import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NavMenu from './Menu/NavMenu'
import { logoutUser } from "../redux/actions/UserActions";
import { loadInitialDataSocket } from '../redux/actions/SocketActions'
import { Menu, notification, Avatar, Dropdown, Layout, Row, Col,Drawer,Button  } from 'antd';
import io from "socket.io-client";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import history from '../helpers/history';
import { SOCKET_BASE_URL,BASE_URL } from "../services/ApiConstant";


const TemplateNew = (props) => {
  const [collapsed, setCollapsed]   = useState(false);
  const [socket, setSocket]         = useState(null);
  const [drawerOpen,setDrawerOpen]  = useState(false)
  const [deviceType,setDeviceType]  = useState("desktop")

  const userData = useSelector(state => state.user.userData);
  const errorData = useSelector(state => state.errors.error);

  const dispatch = useDispatch();

  const { Header, Content, Sider } = Layout;
  
  useEffect(() => {
    setCollapsed(props.sideBar);
    console.log(props)
  }, [props]);


  useEffect(() => {
    setSocket(io(SOCKET_BASE_URL));
  }, []);

  useEffect(() => {
    dispatch(loadInitialDataSocket(socket, userData))
  }, [socket]);
  

  useEffect(() => {
    if(errorData)
      console.log(errorData)
  }, [errorData]);


  useEffect(() => {
    if(window.innerWidth < 992) {
      setCollapsed(true);
      setDeviceType("mobile")
    }
    else{
      setCollapsed(false);
      setDeviceType("desktop")
    }
  }, [window.innerWidth]);

  const handleOnCollapse = () => {
    setCollapsed(prevState => !prevState);
  };




  const getCollapseIcon = () => {
    if (collapsed) {
      return (
        <MenuUnfoldOutlined onClick={handleOnCollapse} className="trigger" style={{ color: 'white', fontSize: '1.5rem' }} />
      );
    }
    return <MenuFoldOutlined onClick={handleOnCollapse} className="trigger" style={{ color: 'white', fontSize: '1.5rem' }} />;
  };

  const menuItems = [
  {
    key: 'userDetail',
    icon: <UserOutlined />,
    label: (userData.name),
  },
  {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Çıkış',
  }
  ];
  
  const handleMenuClick = (e) => {
    if (e.key === 'userDetail') {
      history.push('/user')
    }
    if (e.key === 'logout') {
      dispatch(logoutUser());
    }
  };

  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Header className="header">
          <Row style={{ paddingTop: 0 }}>
              <Col xs={2} sm={4} md={6} lg={8} xl={2}>
                { deviceType == "desktop" ? getCollapseIcon() : <Button onClick={()=> setDrawerOpen(!drawerOpen)} ><MenuUnfoldOutlined/></Button> }
              </Col>
              
              <Col xs={20} sm={16} md={12} lg={8} xl={20}>
                <Row align="middle">
                  <Col span="12" align="right"><img src="./favicon.png" width="20px" /> </Col>
                  <Col span="12" align="left"><h1 style={{ fontSize: '1.125rem', fontFamily: 'Poppins,sans-serif', padding: '0px 0px 0px 7px', fontWeight: 400, display: 'block', color: 'white' }}>Aybim ERP</h1> </Col>
                </Row>
              </Col>

              <Col xs={2} sm={4} md={6} lg={8} xl={2} justify="end" align="right">
                <Dropdown menu={{items: menuItems,onClick: handleMenuClick}} className="nav-link nav-pill user-avatar">
                  <Avatar size="large" src={userData.profile_photo ? BASE_URL+'/userphoto/' + userData.profile_photo : 'assets/img/avatars/nobody.jpg'} />
                </Dropdown>
              </Col>
          </Row>
        </Header>

        <Layout style={{ backgroundColor: '#f0f6ff' }}>
          <Content style={{ padding: 15 }}>
            { deviceType == "desktop" && 
            <Sider
              breakpoint="md"
              onCollapse={handleOnCollapse}
              collapsed={collapsed}
              theme="light"
              collapsedWidth={70}
              width={250}
              style={{  position: 'absolute' }}
              className="shadow-2xl "
            >
              <NavMenu />
            </Sider>
            }
            <Drawer title="Aybim ERP" placement="left" open={drawerOpen} onClose={()=>setDrawerOpen(false)} >
              <NavMenu />
            </Drawer>

            <Layout style={{ marginLeft: deviceType == "mobile" ? 0 : (collapsed ? 80 : 260), backgroundColor: '#f0f6ff' }}>
              {props.children}
            </Layout>

          </Content>
        </Layout>
        
      </Layout>
    </>
  );

}

export default TemplateNew;