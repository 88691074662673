import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { useLayer, Arrow  } from "react-laag";
import { Card, Avatar,Button } from 'antd';
import moment from 'moment';
import { BASE_URL } from "../../services/ApiConstant";

const { Meta } = Card;

const StyledMarker = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: red;

  transform: translate(-50%, -50%);

  border-radius: 50% 50% 50% 0;
  border: 4px solid #fff;
  width: 30px;
  height: 30px;
  transform: rotate(-45deg);
  &:hover {
    z-index: 1;
  }

  &:after{
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    background-color: #fff;
  }
`;
const InfoBox = styled.div`
  padding: 0em;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius : 8px;
`;

export default function WorkerMarker({ chatModal, data, show  }) {
  const [isOpen, setOpen] = useState(false);
  
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    overflowContainer: false,
    onDisappear: disappearType => {
      if (disappearType === "full") {
        setOpen(false);
      }
    },
    onOutsideClick: () => setOpen(false)
  });
  

  useEffect(() => {
    
    if(show[0]?.id === data.id) 
      setOpen(true);

  }, [show])

  return (
    <>
      <StyledMarker
        {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
      />
      { (isOpen ) &&
        renderLayer(
          <InfoBox {...layerProps}>
            <Card
                style={{ width: 300,borderRadius : 8 }}
                actions={[
                    <Button danger>Detaylar</Button>,
                    <Button success>Düzenle</Button>,
                    //<Button success onClick={(e)=> chatModal(data) }>Sohbet</Button>
                ]}
            >
                <Meta
                        avatar={
                            <Avatar size={64} src={data?.profile_photo ? BASE_URL + '/userphoto/' + data?.profile_photo : 'assets/img/avatars/nobody.jpg'} />
                        }
                        title={[<> {data.name}  </>]}
                        description={ data?.phone + '\n' + moment(data.last_seen).format('D.MM.YYYY HH:mm') }
                />
            </Card>
            <Arrow {...arrowProps} />
          </InfoBox>
        )}
    </>
  );
}
