import React, { useState, useLayoutEffect } from 'react'
import jwtAuthService from "./services/jwtAuthService";
import { setUserData } from "./redux/actions/UserActions";
import { store } from './redux/store';
import { LOGIN_SUCCESS } from "./redux/actions/LoginActions";


const AuthGuard = (props) => {
    const [load,setLoad] = useState(false);

    useLayoutEffect(() => {
        let userData = jwtAuthService.getUser();
        if(userData != null){
            jwtAuthService.setSession(userData.userData.api_token);

            store.dispatch(setUserData(userData));
            store.dispatch({
                type: LOGIN_SUCCESS
            });
            setLoad(true);
        }
        else{
            setLoad(true);
        }

    }, []);

    return (
        <>
        { load && props.children }
        </>
    )

}

export default AuthGuard;