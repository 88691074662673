import React, { useState, useEffect } from 'react'
import navigation from '../../menu-items'
import { Menu } from 'antd';
import history from "../../helpers/history.js";
import {  useLocation } from 'react-router-dom'

const NavGroup = (items) => {
    let navItems = '';
    const { SubMenu } = Menu;

    navItems = items.map(item => {
        switch (item.type) {
            case 'collapse':
                return (<SubMenu key={item.id} icon={item.icon} title={item.title}>
                    {NavGroup(item.children)}
                </SubMenu>);
            case 'item':
                return (<Menu.Item key={item.id} icon={item.icon} onClick={() => history.push(item.url)}>
                    {item.title}
                </Menu.Item>);
            default:
                return false;
        }
    });

    return (<>{navItems}</>);
};

const NavMenu = () => {
    const location = useLocation()
    const [openKeys, setOpenKeys] = useState(null)
    const [selectedKeys, setSelectedKeys] = useState(null)
    let menuLinks = navigation.items;


    const menuFind = (array = [], url) => {
        for (const item of array) {
          const result = item.url === url ? item : menuFind(item.children, url);
          if(result) return result;
        }
    };

    useEffect(() => {
        //for sub items
        const selected = menuFind(menuLinks,location.pathname);
        setSelectedKeys([selected?.id])
        //for parent
        if (!menuLinks.find(item => item.url === location.pathname)) {
            if (menuLinks.find(item => item.children && item.children.find(item => item.url === location.pathname))) {
              const currentSubItem = menuLinks.find(item => item.children && item.children.find(item => item.url === location.pathname))
              console.log("current parent item", currentSubItem)
              setOpenKeys([currentSubItem?.id])
            }
            return
        }
    }, [menuLinks, location.pathname])

    return (
        <Menu              
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={(openKeys) => {
            setOpenKeys(openKeys);
        }}
        mode="inline" 
        theme="w" 
        className="rounded-lg py-2 px-2 "
        >
            {NavGroup(menuLinks)}
        </Menu>)
}

export default NavMenu;