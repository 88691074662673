import React, { useState, useLayoutEffect } from 'react'
import TemplateNew from '../../components/TemplateNew'
import { Table, Card, Layout, Modal, Form, Button, DatePicker, Input, Row, Col, InputNumber, Typography, Divider } from 'antd';
import {  UserAddOutlined } from '@ant-design/icons';

import moment from 'moment';
import FilterDropdown from '../../components/FilterDropdown'
import AybimTable from '../../components/AybimTable'


const Users = () => {

    const { Column }    = Table;
    const { Content }   = Layout;
    const { Title }     = Typography;

    //Redux
    const [newCustomerForm] = Form.useForm();

    const [customerModal, setCustomerModal] = useState(false);
    const [customerData, setCustomerData] = useState([]);

    const dateFormat = 'DD-MM-YYYY';
    
    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
      
      /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} alanı gereklidir!',
        types: {
          email: '${label} alanı e-posta adresi formatına uygun değil!',
          number: '${label} numara formatında olmalıdır.',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
    };

    const saveCustomerData = () => {
        console.log(newCustomerForm.getFieldsValue());
    }

    return (
        <div>
                <Card
                    title="Kullanıcılar"
                    extra={<Button type="primary" icon={<UserAddOutlined />} shape="round" onClick={() => { setCustomerModal(true); setCustomerData([]); }}>Kullanıcı Ekle</Button>}
                >
                    <AybimTable
                            url = "users/list"
                            tableName = "usersList"
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => { newCustomerForm.setFieldsValue(record); setCustomerModal(true); setCustomerData(record);  },
                                };
                            }}
                    >
                        <Column title="Sıra No" dataIndex="id" sorter="true" />
                        <Column title="Ad Soyad" dataIndex="name" sorter="true" filterDropdown={ (props) => <FilterDropdown  {...props}/> } />
                        <Column title="E-Posta" dataIndex="email" key="email" sorter="true" />
                        <Column title="Plaka" dataIndex="plate_number" />
                    </AybimTable>
                </Card>

            <Modal
                title={"Kullanıcı"}
                centered
                destroyOnClose={true}
                open={customerModal}
                cancelText="Kapat"
                okText="Kaydet"
                onOk={() => saveCustomerData()}
                onCancel={() => setCustomerModal(false)}
                width={'30%'}
            >
                    <Form {...layout} form={newCustomerForm} onValuesChange={(values) => console.log(values)} validateMessages={validateMessages} >
                        <Form.Item name="id" style={{ display: 'none' }} initialValue="0">
                                <Input type="text" />
                        </Form.Item>

                        <Form.Item
                                name="name"
                                label="Ad Soyad"
                                rules={[ { required: true  } ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                                name="phone"
                                label="Telefon No"
                                rules={[ { required: true  } ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                                name="email"
                                label="E-Posta"
                                rules={[ { required: true  } ]}
                        >
                            <Input />
                        </Form.Item>
                        
                    </Form>
            </Modal >


        </div >
    )
}

export default Users