import React, { useEffect } from 'react'
import { Result, Spin } from 'antd';
import { Card, Button, Form, Input, InputNumber } from 'antd';
import { useQuery } from "react-query";
import api from '../services/api';
import TemplateNew from '../components/TemplateNew'

const Settings = () => {
    const [settingsForm] = Form.useForm();

    const { data, isLoading, error, refetch, isRefetching } = useQuery('settings',  () => api.getQueryData({url: 'settings' }));
  
    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
      
      /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} alanı gereklidir!',
        types: {
          email: '${label} alanı e-posta adresi formatına uygun değil!',
          number: '${label} numara formatında olmalıdır.',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
    };
      /* eslint-enable no-template-curly-in-string */
      
    const onFinish = (values) => {
        console.log(values);
    };

    useEffect(()=>{
        settingsForm.setFieldsValue(data?.result)
    },[data])

    return (
        <Spin tip="Yükleniyor..." size="large" spinning={(isLoading || isRefetching)} style={{ color: '#67be23' }}>
            <Card title="Şirket Ayarları">
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={onFinish}
                    style={{
                        maxWidth: 700,
                    }}
                    validateMessages={validateMessages}
                    form={settingsForm}
                >
                    <Form.Item
                    name="company_name"
                    label="Şirket Adı"
                    rules={[ { required: true  } ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                    name="company_address"
                    label="Şirket Adresi"
                    rules={[ { required: true  } ]}
                    >
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                    name="company_mail"
                    label="Şirket E-Mail"
                    rules={[ { required: true, type: 'email' } ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                    name="company_phone"
                    label="Şirket Telefonu"
                    rules={[ { required: true  } ]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item name={['user', 'website']} label="Website">
                    <Input />
                    </Form.Item>

                    <Form.Item name={['user', 'introduction']} label="Introduction">
                    <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                    wrapperCol={{
                        ...layout.wrapperCol,
                        offset: 8,
                    }}
                    >
                    <Button type="primary" htmlType="submit">
                        Kaydet
                    </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Spin>
    )
}

export default Settings
