import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import { Redirect } from 'react-router-dom';
import history from "../../helpers/history.js";
import '../../index.css';
import { Form, Input, Button, Checkbox, Card, Col, Row, Spin, Alert } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const LoginPage = () => {
  const login = useSelector(state => state.login);
  const dispatch = useDispatch();

  const colProps = { xs: 24, sm: 12, md: 8 };

  const onFinish = (values) => {
    //console.log('Received values of form: ', values);
    dispatch(loginWithEmailAndPassword(values))
  };

  return (
    login.success ? <Redirect to='/' /> :
    <div style={{ backgroundImage: `url('/assets/img/login.jpg')` }}>
      <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <div className="tail-container tail-mx-auto">
          <div className="tail-flex tail-justify-center tail-px-6 tail-my-12">
              <div className="tail-w-full xl:w-3/4 lg:w-11/12 tail-flex">
                  <div
                      className="tail-w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover tail-rounded tail-rounded-r-none"
                      style={{ backgroundImage: `url('assets/img/bg1.jpg')` }}
                  ></div>
                  <div className="tail-w-full lg:w-1/2 tail-bg-white tail-p-5 tail-rounded tail-rounded-l-none">
                      <Spin tip="Giriş yapılıyor..." spinning={login.loading}>
                      <h3 className="tail-pt-4 tail-text-2xl tail-text-center">Aybim ERP</h3>

                      <Form
                          name="login_form"
                          className="login-form"
                          initialValues={{
                            remember: true,
                            email: "",
                            password: ""
                          }}
                          onFinish={onFinish}
                        >
                      <div className="tail-px-8 tail-pt-6 tail-pb-8 tail-mb-4 tail-bg-white tail-rounded">
                          {login.error &&
                              <Alert message="Giriş yapılamadı.Lütfen Bilgilerinizi kontrol ediniz!" type="error" showIcon />
                          }

                          <label className="tail-block tail-mb-2 tail-text-sm tail-font-bold tail-text-gray-700" htmlFor="login_form_email">
                             E-Posta Adresi
                          </label>

                          <Form.Item
                            name="email"
                            rules={[
                              {
                                type: 'email',
                                message: 'Lütfen e-posta adresinizi uygun formatta giriniz!',
                              },
                              {
                                required: true,
                                message: 'Lütfen e-posta adresi giriniz!',
                              },
                            ]}
                            hasFeedback
                          >
                            <Input 
                              className="tail-w-full tail-px-3 tail-py-2 tail-text-sm tail-leading-tight tail-text-gray-700 tail-border tail-rounded tail-shadow tail-appearance-none tail-focus:outline-none tail-focus:shadow-outline" 
                              prefix={<UserOutlined className="tail-site-form-item-icon" />} 
                              placeholder="E-Posta" 
                            />
                          </Form.Item>

                          <label className="tail-block tail-mb-2 tail-text-sm tail-font-bold tail-text-gray-700" htmlFor="login_form_password">
                            Şifre
                          </label>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: 'Lütfen şifrenizi giriniz!',
                              },
                            ]}
                            hasFeedback
                          >
                            <Input
                              className="tail-w-full tail-px-3 tail-py-2 tail-mb-3 tail-text-sm  tail-leading-tight  tail-text-gray-700  tail-border  tail-rounded  tail-shadow  tail-appearance-none  tail-focus:outline-none  tail-focus:shadow-outline"
                              prefix={<LockOutlined className="tail-site-form-item-icon" />}
                              type="password"
                              placeholder="Password"
                            />
                          </Form.Item>

                          <div className="tail-mb-4">
                              <input className="tail-mr-2 tail-leading-tight" type="checkbox" id="checkbox_id" />
                              <label className="tail-text-sm" htmlFor="checkbox_id">
                                  Beni Hatırla
                              </label>
                          </div>

                          <div className="tail-mb-6 tail-text-center">
                  
                              <button
                                  className="tail-w-full tail-px-4 tail-py-2 tail-font-bold tail-text-white tail-bg-blue-500 tail-rounded-full tail-hover:bg-blue-700 tail-focus:outline-none tail-focus:shadow-outline"
                                  type="submit"
                              >
                                  Giriş
                              </button>
                          </div>
                          <hr className=" tail-mb-6  tail-border-t" />
                          <div className="tail-text-center">
                              <a
                                  className="tail-inline-block  tail-text-sm  tail-text-blue-500 align-baseline hover:text-blue-800"
                                  onClick={ () =>  history.push('register') }
                              >
                                  Kayıt Ol
                              </a>
                          </div>
                          <div className="tail-text-center">
                              <a
                                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                  onClick={ () =>  history.push('lost-password') }
                              >
                                  Şifrenizi mi unuttunuz?
                              </a>
                          </div>
                      </div>
                      </Form>
                      </Spin>
                  </div>
              </div>
          </div>
      </div>

      </Row>
  </div>


  );
}

export default LoginPage;
