import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.json";
import tr from "./locale/tr.json";

function defaultLanguage() {
  if (process.env.NODE_ENV === "production") {
    return "tr";
  }
  return "tr";
}

i18n
  .use(initReactI18next)
  .init({
    resources: {
        en: {
          translation: en,
        },
        tr: {
          translation: tr,
        },
    },
    lng: defaultLanguage(),

    keySeparator: ".",

    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;
