import React, { useState, useEffect } from 'react'
import { Checkbox, Button, Select, DatePicker,Input, Tabs, Row, Col, Space 
} from 'antd';
import { CarryOutOutlined, MessageOutlined, InfoCircleOutlined, StockOutlined, UserAddOutlined,SearchOutlined } from '@ant-design/icons';

const FilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters, visible, close }) => {

    console.log("Selected Filters",filters);

    const InputOrCheckBox = () => {
        if(filters?.length){
          return(<div><Select
          mode="multiple"
          placeholder="Select Category"
          options={filters}
          dropdownMatchSelectWidth={false}
          /></div>)
        }
        else{
          return (<Input
            placeholder={`Ara`}
            value={selectedKeys}
            onChange={ e =>   setSelectedKeys(e.target.value ? e.target.value : '') }
            //onPressEnter={() => confirm() }
            style={{ marginBottom: 8, display: 'block' }}
          />)
        }
    }

    return (<div style={{ padding: 8 }}>
        <InputOrCheckBox />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm() }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Ara
          </Button>
          <Button onClick={() => { clearFilters(); confirm(); close(); } } size="small" style={{ width: 90 }}>
            Temizle
          </Button>
        </Space>
      </div>)
}

export default FilterDropdown;