import React, { useState, useLayoutEffect } from 'react'

import TemplateNew from '../../components/TemplateNew'
import {
    Tag, Table, Spin, Layout, Modal, Form, Checkbox, Button, Select, DatePicker,Input, Tabs, Row, Col, 
    Badge, InputNumber, Typography, Radio, Card, Tooltip
} from 'antd';
import { CarryOutOutlined, MessageOutlined, InfoCircleOutlined, StockOutlined, FileAddOutlined,SearchOutlined } from '@ant-design/icons';
import GoogleMapReact from 'google-map-react'

import moment from 'moment'
import AybimTable from '../../components/AybimTable'
import FilterDropdown from '../../components/FilterDropdown'
import { useQuery, useMutation } from "react-query";
import  { NumericFormat,PatternFormat }  from "react-number-format";
import api from '../../services/api';

const Customers = () => {
    const [mapCenter,setMapCenter] = useState({ lat: 60.65, lng: 30.09 });
    const [googleKey] = useState("AIzaSyD8iQZahLY5FVfUWlVrb4VGk-Nr72t8VMY");
    const [mapZoom] = useState(8);

    const { Column } = Table;
    const { Content } = Layout;
    const { TabPane } = Tabs;
    const { Title } = Typography;
    const { Option } = Select;

    const [newCustomerForm] = Form.useForm();

    const [customerModal, setCustomerModal] = useState(false);
    const [customerData, setCustomerData] = useState([]);
    const dateFormat = 'DD-MM-YYYY';
    const [marker, setMarker] = useState([]);

    //save&update mutate
    /*
    const {isLoading, isError, error, mutate} = useMutation(createPost, {retry: 3})
    
    async function createPost() {
        const response = await axios.post('https://jsonplaceholder.typicode.com/posts')
        setMessage(response.data)
    }

    mutate({ id: Date.now(), title, description })}
    */
    
    //get tax office list
    const {         
            data        : taxOfficeData, 
            isLoading   : taxOfficeLoading, 
            error       : taxOfficeerror, 
            refetch     : refetchTaskOffice, 
            isRefetching: taxOfficeIsRefetching 
    } = useQuery(["taxOfficeSearch",], () => api.getQueryData({url: 'customers/taxOfficeSearch' }));

    useState(()=>{
        navigator.geolocation.getCurrentPosition(function (position) {
            setMapCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });

        });
    },[])

    const formatDate = (date) => {
        return moment(date).format('D.MM.YYYY HH:mm')
    }

    const saveCustomerData = (customerData) => {
        console.log(customerData);
    }

    const handleEvent = (event) => {
            console.log(event.latLng.lat())
            
            const lat = event.latLng.lat()
            const lng = event.latLng.lng()
            
            fetch(`https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}&format=jsonv2`)
                .then(res => res.json())
                .then(response => {
                    console.log(response);

                    newCustomerForm.setFieldValue("address",response?.address?.suburb)
                    newCustomerForm.setFieldValue("city",response?.address?.city)
                    newCustomerForm.setFieldValue("district",response?.address?.town)
                    newCustomerForm.setFieldValue("latitude",response?.lat)
                    newCustomerForm.setFieldValue("longitude",response?.lon)
            });
            
    }

    const loadMap = (map, maps) => {
   
        let marker = new maps.Marker({
          position: mapCenter,
          map,
          draggable: true
        });

        //marker.addListener('drag', handleEvent);
        marker.addListener('dragend', handleEvent);

    };


    return (
        <div>
                <Card
                    title="Cari Hesaplar"
                    extra={<Button 
                        type="primary" 
                        icon={<FileAddOutlined />} 
                        shape="round"  
                        onClick={() => { setCustomerModal(true); setCustomerData([]);  newCustomerForm.resetFields(); }}>
                        Yeni
                        </Button>}
                >
                        <AybimTable
                            url = "customers/list"
                            tableName = "customerList"
                            onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {  
                                            newCustomerForm.resetFields()
                                            newCustomerForm.setFieldsValue(record)
                                            setCustomerModal(true); 
                                            setCustomerData(record)
                                        },
                                    };
                            }}
                        >
                                <Column title="Müş. No" dataIndex="id" sorter="true" width={75} />
                                <Column title="Ad Soyad / Unvan" dataIndex="customer_name" sorter="false" filterDropdown={ (props) => <FilterDropdown  {...props}/> } />
                                <Column title="Telefon" dataIndex="phone_number" key="phone_number" sorter="true" filterDropdown={ (props) => <FilterDropdown  {...props}/> } />
                                <Column title="Adres" dataIndex="address"  
                                    filterDropdown={ (props) => <FilterDropdown  {...props}/> }  
                                    responsive={['md']}
                                    render={(text, record) => (
                                        <div
                                          className="tail-truncate"
                                          title={text}
                                          style={{ maxWidth: "250px" }}
                                        >
                                        <Tooltip placement="leftBottom" title={text}> {text}</Tooltip>
                                        </div>
                                      )}
                                />
                                <Column title="Bina" dataIndex="building_number"  responsive={['md']}/>
                                <Column title="Daire" dataIndex="apartment_number"  responsive={['md']}/>
                                <Column title="İl" dataIndex="city" filters={ [ { text: 'Kocaeli', value: 'Kocaeli' }, { text: 'New York', value: 'New York' }, ] }  responsive={['md']} />
                                <Column title="İlçe" dataIndex="district"  responsive={['md']}/>
                                <Column title="Toplam İş" dataIndex="totalOperations"  responsive={['md']} />
                        </AybimTable>
                </Card>

            {/* Müşteri Detayları */}
            <Modal
                title={ customerData.customer_name ?  customerData.isCorporation ? "Tedarikçi Detayları" : "Müşteri Detayları - " + customerData.customer_name : 'Yeni Müşteri' }
                centered
                destroyOnClose={true}
                open={customerModal}
                cancelText="Kapat"
                okText="Kaydet"
                onOk={() => {
                    newCustomerForm.submit()
                }}
                onCancel={() => setCustomerModal(false)}
                width={'60%'}
                bodyStyle={{height: '500px' }}
            >
                    <Form form={newCustomerForm} onFinish={(values)=> saveCustomerData(values) } onFieldsChange={(e) => setCustomerData(newCustomerForm.getFieldsValue()) } >
                        <Form.Item name="id" style={{ display: 'none' }} initialValue="0">
                            <Input type="text" />
                        </Form.Item>

                        <Tabs defaultActiveKey="1" tabPosition="left" >
                            <TabPane tab={<><InfoCircleOutlined /> Genel Bilgileri</>} key="1" >
                                <Row gutter={[16, 16]} style={{ marginLeft: 10 }}>
                                    <Col span={12}>
                                    <Form.Item name="isCorporation" rules={[{ required: true, message: 'Lütfen müşteri türünü giriniz!' }]}  >
                                        <Radio.Group>
                                            <Radio value={true}>Kurumsal</Radio>
                                            <Radio value={false}>Bireysel</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]} style={{ marginLeft: 10 }}>
                                    <Col span={12}>
                                        
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Müşteri / Tedarikçi Kodu :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="customer_name" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input  />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {(!customerData.isCorporation) &&
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Ad :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="firstname" rules={[{ required: true, message: 'Lütfen Ad Giriniz!' }]} >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        }

                                        {(customerData.isCorporation) &&
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Ünvan :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="name" rules={[{ required: true, message: 'Lütfen Unvan Giriniz!' }]} >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        }

                                        {(customerData.isCorporation) &&
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Vergi No :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="taxNumber" rules={[{ required: true, message: 'Lütfen vergi no giriniz!' }]} >
                                                    <InputNumber max="9999999999" style={{ width: 200 }} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        }

                                        <Row style={{ marginBottom: 8 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Açılış Bakiyesi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="beginningBalance" >
                                                    
                                                    <NumericFormat
                                                        style={{ width: 200 }} 
                                                        customInput={Input}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginBottom: 8 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>E-Arşiv:</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} name="e_archive" valuePropName="checked">
                                                    <Checkbox>E-Arşiv </Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>


                                    </Col>
                                    <Col span={12}>
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Tipi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="type" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Select>
                                                        <Option value={0}>Müşteri</Option>
                                                        <Option value={1}>Tedarikçi</Option>
                                                        <Option value={2}>Müşteri&Tedarikçi</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {(!customerData.isCorporation) &&
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Soyad :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="lastname" rules={[{ required: true, message: 'Lütfen Soyad Giriniz!' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        }

                                        {(customerData.isCorporation) &&
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Kısa Ünvan :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="displayName" rules={[{ required: true, message: 'Lütfen Kısa Unvan Giriniz!' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        }
                                        {(customerData.isCorporation) &&
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Vergi Dairesi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="taxOffice" rules={[{ required: true, message: 'Lütfen vergi no giriniz!' }]} >
                                                    <Select showSearch allowClear>
                                                        <Option value="">Seçiniz</Option>
                                                        {taxOfficeData?.results?.map((taxOffice, key) => (
                                                            <Option value={taxOffice.id} key={taxOffice.id}>{taxOffice.tax_office}</Option>
                                                        ))}
                                                        
                                                    </Select>

                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        }
                                        <Row style={{ marginBottom: 8 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Açılış Bakiyesi Tarihi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="beginningBalanceDateeee" rules={[{ required: true, message: 'Please input your username!' }]} >
                                                    <DatePicker allowClear format={dateFormat}  />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {(customerData.e_archive) &&
                                        <Row style={{ marginBottom: 8 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>E-Arşiv Mail :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="e_archive_mail" rules={[{ required: true, message: 'Please input your username!' }]} >
                                                    <Input  allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        }
                                    </Col>
                                </Row>

                            </TabPane>
                            <TabPane tab={<><CarryOutOutlined /> İletişim Bilgileri</>} key="2" style={{ padding: 30 }} >

                                <Row gutter={[16, 16]} style={{ marginLeft: 10 }}>
                                    <Col span={12}>
                                        
                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={24}>
                                                <Form.Item label={<label>Adres :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="address" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={12}>
                                                <Form.Item label={<label>Adres Tarifi :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="directions" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label={<label>Telefon :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{marginLeft: 10}}
                                                    name="phone_number" rules={[
                                                        {
                                                            required: true,
                                                            message: 'Lütfen telefon numarası giriniz!',
                                                        }
                                                    ]}>
                                                    <PatternFormat placeholder="Telefon" allowClear format="(###) ### ## ##" customInput={Input} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={12}>
                                                <Form.Item label={<label>İl :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="city" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label={<label>İlçe :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{marginLeft: 10}}
                                                    name="district" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={12}>
                                                <Form.Item label={<label>Bina No :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="building_number" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label={<label>Daire No :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{marginLeft: 10}}
                                                    name="apartment_number" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginBottom: 0 }}>
                                            <Col span={12}>
                                                <Form.Item label={<label>Enlem :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                                                    name="latitude" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item label={<label>Boylam :</label>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{marginLeft: 10}}
                                                    name="longitude" rules={[{ required: true, message: 'Lütfen Müşteri Seçiniz' }]} >
                                                    <Input allowClear />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Col>
                                    <Col span={12}>
                                        <>* Harita üzerindeki pin'i sürüklerek adresi güncelleyebilirsiniz.</>
                                        <GoogleMapReact
                                        bootstrapURLKeys={{ key: googleKey }}
                                        defaultCenter={mapCenter}
                                        defaultZoom={mapZoom}
                                        options={{ scrollwheel: true }}
                                        onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        >
                                        </GoogleMapReact>
                                    </Col>
                                </Row>

                            </TabPane>
                            
                            { customerData.id > 0 &&
                            <>
                            <TabPane style={{ padding: 50 }} tab={<><MessageOutlined /> İşlemler <Badge style={{ color: '#fff', backgroundColor: '#736cc7' }} count={customerData.jobDetail?.notes?.length} /> </>} key="3" >
                                <Table
                                    pagination={{ pageSize: 25, pageSizeOptions: ['25', '50', '100', '250', '500'] }}
                                    rowKey={record => record.operation_id}
                                    dataSource={customerData?.operations}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <Column title="Açıklama" dataIndex="description" />
                                    <Column title="Oluşturulma Tarihi" dataIndex="created_at" key="worker" render={ formatDate }  />
                                    <Column title="Başlama Tarihi" dataIndex="start_time"/>
                                </Table>
                            </TabPane>
                            <TabPane tab={<><StockOutlined />Faturalar</>} key="4" >
                                <Table
                                    pagination={{ pageSize: 25, pageSizeOptions: ['25', '50', '100', '250', '500'] }}
                                    rowKey={record => record.operation_id}
                                    dataSource={customerData?.invoices}
                                    style={{ paddingLeft: 10 }}
                                >
                                    <Column title="Fatura No" dataIndex="invoice_number" />
                                    <Column title="Fatura Tarihi" dataIndex="invoiced_at" key="worker" render={ formatDate }  />
                                    <Column title="Vade Tarihi" dataIndex="due_at" key="worker" render={ formatDate }  />
                                    <Column title="Toplam" dataIndex="amount"/>
                                    <Column title="Durum" dataIndex="status" render={(status) => <Tag color={status?.color}>{status?.name}</Tag>} />
                                </Table>
                            </TabPane>
                            </>
                            }
                        </Tabs>
                    </Form>
            </Modal>


        </div>
    )
}

export default Customers