import jwtAuthService from "../../services/jwtAuthService";
import { setUserData } from "./UserActions";
import history from "../../helpers/history.js";

export const LOGIN_ERROR  = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export function loginWithEmailAndPassword({ email, password }) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    jwtAuthService.loginWithEmailAndPassword(email, password)
      .then(response => {
        if(response.success){
          dispatch(setUserData(response));

          history.push({
            pathname: "/"
          });
  
          return dispatch({
            type: LOGIN_SUCCESS
          });
        }
        else{
          return dispatch({
            type: LOGIN_ERROR
          });
        }

      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}