import React, { useEffect,useState } from 'react'
import { useParams } from "react-router-dom";
import TemplateNew from '../../components/TemplateNew'
import { Layout, Select, DatePicker, Radio, Form, Divider, Input, Row, Col, Typography,Spin } from 'antd';


const NewInvoice = () => {
    const { Content } = Layout;
    const { Title } = Typography;
    const { Option } = Select;

    let { id } = useParams();
    const dateFormat = 'DD-MM-YYYY';
    const [invoiceData,setInvoiceData]= useState([])

    useEffect(() => {
        
    }, [id])

    
    useEffect(() => {
        console.log("data geldi",invoiceData);
    }, [invoiceData])

    const calculatePayDate = (e) => {
        var val = e.target.value;
        
        //var dateString = $('#invoiceDate').val().match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
        //var invoiceDate = new Date( dateString[3], dateString[2]-1, dateString[1] );
        //payDate = moment(invoiceDate).add(val, 'day').format('L');
    }

    return (
        <div>
                <Row style={{ paddingBottom: 15 }}>
                    <Col span={16} style={{ paddingRight: 8, paddingLeft: 8 }}>  <Title level={3}>Yeni Fatura</Title> </Col>
                </Row>
                <Spin tip="Yükleniyor..." spinning={invoiceData.isLoading}>
                    <Layout style={{ padding: '1px 1px 1px' }}>
                        <Content
                            className="card"
                            style={{
                                padding: 10,
                                margin: 0,
                                minHeight: 280,
                                borderRadius: '.25rem',
                                backgroundColor : '#fff',
                                boxShadow: '0 10px 40px 0 rgb(18 106 211 / 7%), 0 2px 9px 0 rgb(18 106 211 / 6%)'
                            }}
                        >
                            <>Fatura No : {id} </>
                            <Form  >
                            <Row gutter={24}>
                                <Col span={4}></Col>
                                <Col span={16}>
                                    <Form.Item label="FATURA AÇIKLAMA"><Input /></Form.Item>
                                </Col>
                            </Row>
                            <Divider dashed />
                            <Row gutter={24}>
                                <Col span={4}></Col>
                                <Col span={12}>
                                    <Form.Item label="MÜŞTERİ"><Input value={invoiceData?.data?.customer_name } /></Form.Item>
                                </Col> 
                            </Row>
                            <Divider dashed />
                            <Row gutter={24}>
                                <Col span={4}></Col>
                                <Col span={12}>
                                    <Form.Item label="Fatura Tipi">
                                        <Select style={{ width: 250 }} >
                                                <Option value="SATIS">SATIŞ</Option>
                                                <Option value="IADE">İADE</Option>
                                                <Option value="TEVKIFAT">TEVKİFAT</Option>
                                                <Option value="ISTISNA">İSTİSNA</Option>
                                                <Option value="OZELMATRAH">ÖZEL MATRAH</Option>
                                                <Option value="IHRACKAYITLI">İHRAÇ KAYITLI</Option>
                                                <Option value="HKSSATIS">HAL TİPİ FATURA SATIŞ</Option>
                                                <Option value="HKSKOMISYONCU">HAL TİPİ FATURA KOMİSYONCU</Option>                                   
                                        </Select>
                                    </Form.Item>
                                </Col> 
                            </Row>

                            <Row gutter={24}>
                                <Col span={4}></Col>
                                <Col span={12}>
                                    <Form.Item label="Düzenlenme Tarihi">
                                        <DatePicker format={dateFormat}  onChange={(date,dateString) => { console.log(date); } } />
                                    </Form.Item>
                                </Col> 
                            </Row>


                            <Row gutter={24}>
                                <Col span={4}></Col>
                                <Col span={12}>
                                    <Form.Item label="Ödeme Planı">
                                        <Radio.Group defaultValue="a" buttonStyle="solid" onChange={ calculatePayDate }>
                                            <Radio.Button value="0">Aynı Gün</Radio.Button>
                                            <Radio.Button value="7">7 Gün</Radio.Button>
                                            <Radio.Button value="14">14 Gün</Radio.Button>
                                            <Radio.Button value="30">30 Gün</Radio.Button>
                                            <Radio.Button value="45">45 Gün</Radio.Button>
                                            <Radio.Button value="60">60 Gün</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col> 
                            </Row>

                            <Row gutter={24}>
                                <Col span={4}></Col>
                                <Col span={12}>
                                    <Form.Item label="Ödeme Tarihi">
                                        <DatePicker format={dateFormat}  onChange={(date,dateString) => { console.log(date); } } />
                                    </Form.Item>
                                </Col> 
                            </Row>

                            <Row gutter={24}>
                                <Col span={4}></Col>
                                <Col span={12}>
                                    <Form.Item label="Para Birimi">
                                        <Select style={{ width: 250 }}>
                                            <Option value="USD">USD</Option>
                                            <Option value="EUR">EUR</Option>
                                        </Select>
                                    </Form.Item>
                                </Col> 
                            </Row>




                            </Form>

                        </Content>
                    </Layout>
                </Spin>
        </div>
    )
}

export default NewInvoice