import { React } from 'react';
import TemplateNew from './TemplateNew'
import { Statistic, Card, Row, Col, Layout,Typography } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const HomePage = () => {
    const { Content } = Layout;
    const { Title } = Typography;

    return (
        <div>
                <Row gutter={[16, 16]}>
                <Col md={24}>
                    <Row gutter={[16, 16]}>
                    <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                                <Card
                                    bodyStyle={{
                                        paddingBottom: 20,
                                        paddingLeft: 20
                                    }}
                                    style={{
                                        backgroundColor: "#35c7bd",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "right",
                                        backgroundImage : "linear-gradient(115deg, #35c7bd, #4ee2d8)"
                                    }}
                                >
                                    <div className="safe-card content-cards">
                                        <Title level={3} className="text">Bekleyen İş</Title>
                                        <div className="number">0</div>
                                    </div>
                                </Card>
                            </Col>
                        <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                            <Card
                                bodyStyle={{
                                    paddingBottom: 20,
                                    paddingLeft: 20
                                }}
                                style={{
                                    backgroundColor: "#51d8ff",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "right",
                                    backgroundImage : "linear-gradient(294deg, #51d8ff, #5cdec7)"
                                }}
                            >
                                    <div className="bank-card content-cards">
                                        <Title level={3} className="text">Tamamlanan İş</Title>
                                        <div className="number">0</div>
                                    </div>
                            </Card>
                        </Col>
                        <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                            <Card
                                bodyStyle={{
                                    paddingBottom: 20,
                                    paddingLeft: 20
                                }}
                                style={{
                                    backgroundColor: "#51d8ff",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "right",
                                    backgroundImage : "linear-gradient(294deg, #51d8ff, #5cdec7)"
                                }}
                            >
                                    <div className="bank-card content-cards">
                                        <Title level={3} className="text">İptal İş</Title>
                                        <div className="number">0</div>
                                    </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                    <Card
                        bodyStyle={{
                            height: 550,
                            padding: 0,
                        }}
                        title="Map"
                    >
                    </Card>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <Card
                        bodyStyle={{
                            height: 550,
                            overflowY: "scroll",
                        }}
                        title="Son işlemler"
                    >
                    </Card>
                </Col>
            </Row>
        </div>
    );

}

export default HomePage;
