import { SET_ERROR } from '../actions/errorActions';

const initalState = {
    error: ""
};

export const errorReducer = (state=initalState,action) => {
    switch(action.type){
        case SET_ERROR :
            return {
                ...state,
                error : action.error
            }
        default :
            return state
    }
}

