import React, { useState, useLayoutEffect } from 'react'
import { Table, Spin } from 'antd';
import { useQuery } from "react-query";
import api from '../services/api';
import { useEffect } from 'react';


const getData = async ({url, pagination, filters, sorter}) => {
    let urlData = url+"?draw=" + pagination?.current + "&start=" + pagination?.pageSize * (pagination?.current - 1) + "&length=" + pagination?.pageSize+"&"+sorter?.field+"="+sorter?.order;    
    return api.getQueryData({url: urlData, params: filters, method: 'GET' })
};


const AybimTable = ({ url, tableName, onRow, children, refetchOnWindowFocus = false, returnData, refetchTable })=>{


    const [pagination, setPagination] = useState({ 
        current: 1, 
        pageSize: 25, 
        showSizeChanger: true, 
        showQuickJumper: true,
        showTotal: (total, range) => (
            <span style={{ left: 0, position: "absolute" }}>
              Showing {range[0]}-{range[1]} of {total}
            </span>
        ),       
        defaultPageSize: 25, 
        pageSizeOptions: ['25', '50', '100', '250', '500'] 
    });
    
    const [filters, setFilters] = useState([]);
    const [sorter, setSorter] = useState();

    const dateFormat = 'DD-MM-YYYY';

    //React Query
    const { data, isLoading, error, refetch, isRefetching } = useQuery(tableName, 
        () => getData({url, pagination, filters, sorter}),
        {
            refetchOnWindowFocus,
            staleTime: 0,
            cacheTime: 0,
            refetchInterval: 0 
        }
    );

    useEffect(()=>{
        refetchTable && refetch()
    },[refetchTable]);

    useLayoutEffect(() => {
        setPagination({ ...pagination, total: data?.recordsTotal });

        if(typeof returnData === 'function')
            returnData(data)

    }, [data])


    const handleTableChange = (pagination, filters, sorter) => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;

        setPagination(pagination)
        setSorter(sorter)
        setFilters(filters);
        setTimeout(() => {
            refetch()
        }, 800);
        
    };

    return (
        <>
            <Spin tip="Yükleniyor..." spinning={(isLoading || isRefetching)} style={{ color: '#67be23' }}>
                <Table
                    bordered
                    dataSource={data?.data}
                    pagination={pagination}
                    rowKey="id"
                    onRow={ onRow }
                    onChange={handleTableChange}
                    style={{ paddingLeft: 10 }}
                    scroll={{ y: 'calc(70vh - 4em)' }}
                    rowClassName={(record, index) => 
                        index % 2 === 0 ? 'table-row-light' :  'table-row-dark'
                    }
                    size="middle"
                >
                    {children}
                </Table>
            </Spin>
        </>
    )
}

export default AybimTable;