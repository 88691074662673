import React, { useState, useLayoutEffect } from 'react'
import TemplateNew from '../../components/TemplateNew'
import { Table, Spin, Layout, Modal, Form, Button, DatePicker, Input, Row, Col, InputNumber,Select, Typography, Radio,Card, message } from 'antd';
import {  FileAddOutlined } from '@ant-design/icons';

import moment from 'moment';
import FilterDropdown from '../../components/FilterDropdown'
import AybimTable from '../../components/AybimTable'

import  { NumericFormat }  from "react-number-format";
import Checkbox from 'antd/es/checkbox';
import { useQuery,useMutation } from "react-query";
import api from '../../services/api';

const Items = () => {

    const { Column }    = Table;
    const { Content }   = Layout;
    const { Title }     = Typography;
    const { Option } = Select;

    const [newItemForm] = Form.useForm();

    const [modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);

    const dateFormat = 'DD-MM-YYYY';

    const { data : currencyData, isLoading, error, refetch, isRefetching } = useQuery('currencyList',  () => api.getQueryData({url: 'currencies', method: 'GET' }));

    const layout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };
      
      /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} alanı gereklidir!',
        types: {
          email: '${label} alanı e-posta adresi formatına uygun değil!',
          number: '${label} numara formatında olmalıdır.',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
    };
      /* eslint-enable no-template-curly-in-string */

    //save item
    const saveItem = useMutation(params => { return  api.getQueryData({url: 'items/save', params}) } ,  {
        onSuccess: async (data, variables, context) => {
            console.log(data.message)
            if(data.success){
                setModal(false)
                message.success('Malzeme kayıdı başarıyla yapıldı.')
                //refetch()
            }
            else{
                message.error(data.message)
            }
        } 
    })

    const currencySelector = (name) => (
        <Form.Item name={name} label="Para Birimi" rules={[ { required: true  } ]} noStyle>
          <Select style={{  width: 100 }} >
            {currencyData?.result.map((currency, key) => (
                <Option value={currency?.id} key={currency?.id}>{currency?.symbol} {currency?.code} </Option>
             ))}  
          </Select>
        </Form.Item>
    );

    return (
        <>
                <Card
                    title="Hizmet ve Ürünler"
                    extra={<Button type="primary" icon={<FileAddOutlined />} shape="round" onClick={() => { setSelectedData([]); setModal(true); newItemForm.resetFields(); }}>Yeni</Button>}
                >
                            <AybimTable
                                url = "items/list"
                                tableName = "itemsList"
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            newItemForm.resetFields()
                                            newItemForm.setFieldsValue(record)
                                            setModal(true); 
                                            setSelectedData(record)
                                        },
                                    };
                                }}
                                refetchTable={ !saveItem.isLoading }
                                returnData={(data)=> console.log(data) }
                            >
                                    <Column title="#" dataIndex="id" sorter="true" />
                                    <Column title="Adı" dataIndex="name" sorter="true" filterDropdown={ (props) => <FilterDropdown  {...props}/> } />
                                    <Column title="Kodu" dataIndex="sku" sorter="true" filterDropdown={ (props) => <FilterDropdown  {...props}/> } />
                                    <Column title="Alış Fiyatı" dataIndex="purchase_price" responsive={['md']}  />
                                    <Column title="Satış Fiyatı" dataIndex="sale_price" responsive={['md']}   />
                                    <Column title="Barkod" dataIndex="barcode" responsive={['md']}   />
                                    <Column title="Stok Takibi" dataIndex="inventory_tracking" responsive={['md']} 
                                    filters={[{ label: 'Yapılsın', value: '1' }, { label: 'Yapılmasın', value: '0' },]} filterDropdown={ (props) => <FilterDropdown  {...props}/> }
                                    />
                                    <Column title="Kritik Stok Uyarısı" dataIndex="critical_stock_warn" responsive={['md']}   />
                                    <Column title="Kritik Stok Miktarı" dataIndex="critical_stock" responsive={['md']}  />
                            </AybimTable>
                </Card>

            <Modal
                title={"Hizmet & Ürün Ekle "}
                centered
                destroyOnClose={true}
                open={modal}
                cancelText="Kapat"
                okText="Kaydet"
                onOk={() => {
                    newItemForm.submit()
                }}
                onCancel={() => setModal(false)}
                width={'40%'}
                okButtonProps={ { loading: saveItem.isLoading } }
            >
                        <Form
                            {...layout}
                            form={newItemForm} 
                            onFinish={(values)=> saveItem.mutate(values) } 
                            onFieldsChange={(e) => setSelectedData(newItemForm.getFieldsValue()) }
                            name="nest-messages"
                            validateMessages={validateMessages}
                        >
                            <Form.Item name="id" style={{ display: 'none' }} initialValue="0">
                                <Input type="text" />
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label="Hizmet & Ürün Adı"
                                rules={[ { required: true  } ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="sku"
                                label="Malzeme Kodu"
                                rules={[ { required: true  } ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                            name="description"
                            label="Açıklama"
                            rules={[ { required: true } ]}
                            >
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item
                            name="barcode"
                            label="Barkod"
                            rules={[ { required: true  } ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item name="purchase_price" label="Alış Fiyatı" >
                                <NumericFormat
                                    customInput={Input}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    addonAfter={currencySelector("purchase_currency")}
                                />
                            </Form.Item>

                            <Form.Item name="sale_price" label="Satış Fiyatı">
                                <NumericFormat
                                    customInput={Input}
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    decimalScale={2}
                                    addonAfter={currencySelector("sale_currency")}
                                />
                            </Form.Item>

                            <Form.Item name="inventory_tracking" label="Stok Takibi">
                                <Radio.Group>
                                    <Radio value={1}>Yapılsın</Radio>
                                    <Radio value={0}>Yapılmasın</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.inventory_tracking !== currentValues.inventory_tracking}
                            >
                            {({ getFieldValue }) => getFieldValue('inventory_tracking') === 1 &&
                            (
                                <Form.Item name="critical_stock_warn" rules={[{ required: true }]}  >
                                    <Checkbox>Kritik Stok Uyarısı</Checkbox>
                                </Form.Item>) 
                            }
                            </Form.Item>
                            
                            <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => prevValues.critical_stock_warn !== currentValues.critical_stock_warn}
                            >
                            {({ getFieldValue }) => getFieldValue('critical_stock_warn') === "checked" &&
                            (
                                <Form.Item name="critical_stock" rules={[{ required: true }]}  >
                                    <Input />
                                </Form.Item>) 
                            }
                            </Form.Item>

                        </Form>

            </Modal >


        </>
    )
}

export default Items