import React from 'react'
import { Result, Button } from 'antd';
import history from "../helpers/history.js";

function page404() {
    return (
        <div>
            <Result
                status="404"
                title="404"
                subTitle="Aradığınız sayfa bulunamadı. Kaybolmuş olabilirsiniz :)"
                extra={<Button type="primary" onClick={ () => history.push({ pathname: "/"}) }>Anasayfa</Button>}
            />
        </div>
    )
}

export default page404
