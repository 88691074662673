import React, { useLayoutEffect } from 'react'
import TemplateNew from '../../components/TemplateNew'
import history from "../../helpers/history.js";
import { Table, Spin, Layout, Button, Row, Card, Typography} from 'antd';
import {FileAddFilled} from '@ant-design/icons';
import AybimTable from '../../components/AybimTable'


const Invoices = () => {
    const { Column } = Table;
    const { Content } = Layout;
    const { Title } = Typography;


    return (
        <div>
                <Card
                        title="Faturalar"
                        extra={<Button type="primary" icon={<FileAddFilled/>} shape="round" onClick={() => {  history.push('invoices/new') }}>Fatura Ekle</Button>}
                >
                    <AybimTable
                        url = "invoice/list"
                        tableName = "invoiceList"
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => { history.push('invoices/edit/'+record.id); },
                            };
                        }}
                    >
                        <Column title="Sıra No" dataIndex="id" sorter="true" />
                        <Column title="Ad Soyad / Unvan" render={(customer) => customer?.customer_name} sorter="true" />
                        <Column title="Fatura No" dataIndex="invoice_number" key="invoice_number" sorter="true" />
                        <Column title="Vade Durumu" dataIndex="dueCount" render={(html) =>  (<div>{html}</div>)  } />
                        <Column title="Toplam" dataIndex="amount" />
                    </AybimTable>
                </Card>
        </div>
    )
}

export default Invoices