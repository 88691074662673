export const currencyFormatter = (
    input = 0,
    showZeros = false,
    currencyFormat = "TR-TRY",
  ) => {
    try {
      const [numberFormat, currency] = currencyFormat?.split("-");
      const Formatter = new Intl.NumberFormat(numberFormat ?? "TR", {
        style: "currency",
        currency: currency ?? "TRY",
        minimumFractionDigits: showZeros ? 2 : 0,
      });
      if (isNaN(+input)) {
        return Formatter.format(0);
      }
      return Formatter.format(+input);
    } catch (e) {
      return input;
    }
};

export const getTaxAmount = (record = {}) => {
  const taxRate = Number.isFinite(+record.tax_rate)
    ? record.tax_rate
    : 18;

  return ((taxRate * (record.price || 0)) / 100) * (record.qty || 0);
};

export const getTotalWithTax = (record = {}) => {
  const { tax_rate, qty, price } = record;
  const taxRate = Number.isFinite(+tax_rate) ? tax_rate : 18;

  let amount = (qty || 0) * (price || 0);
  amount += (amount * taxRate) / 100;
  return amount;
};

export const getGrandTotalWithoutTax = (stock) =>
  stock.reduce((acc, curr) => acc + (curr.price || 0) * (curr.qty || 0), 0);


export const getGrandTotalWithTax = (stock = []) =>
  stock.reduce((acc, curr) => acc + getTotalWithTax(curr), 0);

export const getGrandTaxAmount = (
  stock = [],
  taxRate,
) => {
  let filtered = stock;
  if (Number.isFinite(taxRate)) {
    filtered = stock.filter((item) => (item.tax_rate || 18) === taxRate);
  }

  return filtered.reduce((acc, curr) => acc + getTaxAmount(curr), 0);
};

export const getAmountFromPercentageTotal = (percentage = 0, total = 0) =>
  +((percentage * total) / 100).toFixed(2);

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const getUnitPriceFromGrossTotal = (record = {}) => {
  const { tax_rate, total_price } = record;
  const taxRate = Number.isFinite(+tax_rate) ? tax_rate : 18;

  let grossTotal = +total_price || 0;
  const unitPrice = (100 * grossTotal) / (100 + taxRate);
  return unitPrice;
};