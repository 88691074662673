import {
    SOCKET_ERROR,
    SOCKET_SUCCESS,
    SOCKET_LOADING
  } from "../actions/SocketActions";
  
  const initialState = {};
  
  export const SocketReducer = function(state = initialState, action) {
    switch (action.type) {
      case SOCKET_ERROR: {
        return {
          ...state,
          ...action.data
        };
      }
      case SOCKET_SUCCESS: {
        return {
          ...state
        };
      }
      case SOCKET_LOADING: {
        return state;
      }
      default: {
        return state;
      }
    }
  };
   