import React, { useState, useLayoutEffect } from 'react'
import TemplateNew from '../../components/TemplateNew'
import { Table, Layout, Form, Button, Typography, Card } from 'antd';
import {  UserAddOutlined } from '@ant-design/icons';

import moment from 'moment';
import FilterDropdown from '../../components/FilterDropdown'
import AybimTable from '../../components/AybimTable'


const Inventory = () => {

    const { Column }    = Table;
    const { Content }   = Layout;
    const { Title }     = Typography;

    //Redux
    const [newCustomerForm] = Form.useForm();

    const [customerModal, setCustomerModal] = useState(false);
    const [customerData, setCustomerData] = useState([]);

    const dateFormat = 'DD-MM-YYYY';

    const saveCustomerData = () => {
        console.log(newCustomerForm.getFieldsValue());
    }

    return (
        <div>
                <Card
                    title="Envanter"
                    extra={<Button type="primary" icon={<UserAddOutlined />} shape="round" onClick={() => { setCustomerModal(true); setCustomerData([]); }}>Hizmet Ekle</Button>}
                >
                        <AybimTable
                            url = "inventory/list"
                            tableName = "inventoryList"
                            returnData={(data)=> console.log(data) }
                        >
                                <Column title="Sıra No" dataIndex="inventoryId" sorter="true" />
                                <Column title="Depo" dataIndex="warehouse" sorter="true" filterDropdown={ (props) => <FilterDropdown  {...props}/> } render={(data) => data?.warehouse_name }/>
                                <Column title="Ürün Kodu" dataIndex="part" key="iBan" sorter="true" render={(data) => data?.name} />
                                <Column title="Ürün Adı" dataIndex="part" key="iBan" sorter="true" render={(data) => data?.description}/>
                                <Column title="Adet" dataIndex="part_qty" />
                                <Column title="Seri No" dataIndex="lotNumber" />
                        </AybimTable>
                </Card>
        </div >
    )
}

export default Inventory