import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import { Redirect } from 'react-router-dom';
import history from "../../helpers/history.js";
import '../../index.css';
import { Form, Input, Button, Checkbox, Card, Col, Row, Spin, Alert } from "antd";
import { UserOutlined, LockOutlined, MailOutlined, PhoneOutlined, ShopOutlined } from '@ant-design/icons';

const RegisterPage = () => {
  const login = useSelector(state => state.login);
  const dispatch = useDispatch();

  const colProps = { xs: 24, sm: 12, md: 8 };

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  return (
    login.success ? <Redirect to='/' /> :
    <div style={{ backgroundImage: `url('/assets/img/login.jpg')` }}>
      <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <div className="container mx-auto">
          <div className="flex justify-center px-6 my-12">
              <div className="w-full xl:w-3/4 lg:w-11/12 flex">
                  <div
                      className="w-full h-auto bg-gray-400 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg"
                      style={{ backgroundImage: `url('assets/img/new_user.jpg')` }}
                  ></div>
                  <div className="w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none">
                      <Spin tip="Giriş yapılıyor..." spinning={login.loading}>
                      <h3 className="pt-4 text-2xl text-center">Kayıt Ol</h3>

                      <Form
                          name="login_form"
                          className="login-form"
                          onFinish={onFinish}
                      >

                      <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded">

                          <div class="mb-4 md:flex md:justify-between">
                            <div class="mb-4 md:mr-2 md:mb-0">
                              <label class="block mb-2 text-sm font-bold text-gray-700" for="firstName">
                                Adı
                              </label>
                              <Form.Item
                                name="firstName"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Lütfen adınızı giriniz!',
                                  },
                                ]}
                                hasFeedback
                              >
                              <Input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="Adı"
                                prefix={<UserOutlined  className="site-form-item-icon" />}
                              />
                              </Form.Item>
                            </div>
                            <div class="md:ml-2">
                              <label class="block mb-2 text-sm font-bold text-gray-700" for="lastName">
                                Soyadı
                              </label>
                              <Form.Item
                                name="lastName"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Lütfen adınızı giriniz!',
                                  },
                                ]}
                                hasFeedback
                              >
                              <Input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="Soyadı"
                                prefix={<UserOutlined  className="site-form-item-icon" />}
                              />
                              </Form.Item>
                            </div>
                          </div>

                          <div class="mb-4">
                            <label class="block mb-2 text-sm font-bold text-gray-700" for="email">
                              Şirket Adı
                            </label>
                            <Form.Item
                                name="company_name"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Lütfen şirket adınızı giriniz!',
                                  },
                                ]}
                                hasFeedback
                              >
                              <Input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="Şirket adı"
                                prefix={<ShopOutlined  className="site-form-item-icon" />}
                              />
                              </Form.Item>
                          </div>

                          <div class="mb-4">
                            <label class="block mb-2 text-sm font-bold text-gray-700" for="email">
                              Şirket Telefonu
                            </label>
                            <Form.Item
                                name="company_phone"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Lütfen eposta giriniz!',
                                  },
                                ]}
                                hasFeedback
                              >
                              <Input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="Şirket Telefonu"
                                prefix={<PhoneOutlined  className="site-form-item-icon" />}
                              />
                              </Form.Item>
                          </div>

                          <div class="mb-4">
                            <label class="block mb-2 text-sm font-bold text-gray-700" for="email">
                              Şirket E-Posta Adresi
                            </label>
                            <Form.Item
                                name="company_mail"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Lütfen eposta giriniz!',
                                  },
                                ]}
                                hasFeedback
                              >
                              <Input
                                className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="E-Posta"
                                prefix={<MailOutlined  className="site-form-item-icon" />}
                              />
                              </Form.Item>
                          </div>

                          <div class="mb-4 md:flex md:justify-between">
                            <div class="mb-4 md:mr-2 md:mb-0">
                              <label class="block mb-2 text-sm font-bold text-gray-700" for="password">
                                Şifre
                              </label>
                              <Form.Item
                                name="password"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Lütfen şifre giriniz!',
                                  },
                                ]}
                                hasFeedback
                                type="password"
                              >
                              <Input.Password 
                                className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="******************"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                              />
                              </Form.Item>
                            </div>

                            <div class="md:ml-2">
                              <label class="block mb-2 text-sm font-bold text-gray-700" for="c_password">
                                Şifre (Tekrar)
                              </label>
                              <Form.Item
                                name="c_password"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Lütfen şifre giriniz!',
                                  },
                                ]}
                                hasFeedback
                              >
                              <Input.Password 
                                className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                placeholder="******************"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                              />
                              </Form.Item>
                            </div>
                          </div>

                          <div className="mb-6 text-center">
                  
                              <button
                                  className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                                  type="submit"
                              >
                                  Kayıt Ol
                              </button>
                          </div>
                          <hr className="mb-6 border-t" />

                          <div className="text-center">
                              <a
                                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                  onClick={ () =>  history.push('lost-password') }
                              >
                                  Şifremi Unuttum
                              </a>
                          </div>

                          <div className="text-center">
                              <a
                                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                                  onClick={ () =>  history.push('login') }
                              >
                                  Hesabınız var mı ? Giriş Yapın
                              </a>
                          </div>
                      </div>
                      </Form>
                      </Spin>
                  </div>
              </div>
          </div>
      </div>

      </Row>
  </div>


  );
}

export default RegisterPage;
