import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { ReactQueryDevtools } from "react-query/devtools";
import AuthGuard from "./AuthGuard";
import {injectStore} from "./services/api";
import { ConfigProvider,theme  } from 'antd';

const queryClientConfig = {
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 1000 * 60 * 10,// 10 minutes
      cacheTime: 1000 * 60 * 10, //10 minutes
      //refetchOnMount: "always",
      //refetchOnWindowFocus: "always",
      //refetchOnReconnect: "always",
      //refetchInterval: 1000 * 60, //60 seconds
      refetchIntervalInBackground: false,
      suspense: false,

    },
    mutations: {
      retry: 2,
    },
  }
}

const container = document.getElementById('root')
const root = createRoot(container)
const queryClient = new QueryClient(queryClientConfig)

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <AuthGuard>
        <ConfigProvider
          theme={{
              token: {
                colorPrimary: '#736cc7',
                //colorBgContainer : '#f5f8fa'
              },
          }}
        >
          <App />
        </ConfigProvider>
      </AuthGuard>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>);

injectStore(store);